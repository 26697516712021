.Full-page {
    width: 100vw;
    background-color: #fff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    display: flex;
    column-gap: 50px;
}

.Full-page .cv-right-dashboard {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    column-gap: 50px;
}

.Full-page .cv-right-dashboard .cv-all-forms .prev-resp-icon {
    color: #fff;
    background-color: #5a4abd;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 20px;
    bottom: 80px;
}

/* HIGHLIGHT CSS OPTION */
.Full-page .cv-right-dashboard .personal-route .personal-edit {
    position: relative;
    padding: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    z-index: 3;
    border: 3px solid #5a4abd !important;
}

.Full-page .cv-right-dashboard .summary-route .summary-edit {
    position: relative;
    padding: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    z-index: 3;
    border: 3px solid #5a4abd !important;
}

.Full-page .cv-right-dashboard .contact-route .contact-edit {
    position: relative;
    padding: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    z-index: 3;
    border: 3px solid #5a4abd !important;
}

.Full-page .cv-right-dashboard .skills-route .skill-edit {
    position: relative;
    padding: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    z-index: 3;
    border: 3px solid #5a4abd !important;
}

.Full-page .cv-right-dashboard .project-route .project-edit {
    position: relative;
    padding: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    z-index: 3;
    border: 3px solid #5a4abd !important;
}

.Full-page .cv-right-dashboard .education-route .education-edit {
    position: relative;
    padding: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    z-index: 3;
    border: 3px solid #5a4abd !important;
}

.Full-page .cv-right-dashboard .job-exp-route .job-exp-edit {
    position: relative;
    padding: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    z-index: 3;
    border: 3px solid #5a4abd !important;
}

.Full-page .cv-right-dashboard .certificate-route .certificate-edit {
    position: relative;
    padding: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    z-index: 3;
    border: 3px solid #5a4abd !important;
}

.Full-page .cv-right-dashboard .interest-route .interest-edit {
    position: relative;
    padding: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    z-index: 3;
    border: 3px solid #5a4abd !important;
}

.Full-page .cv-right-dashboard .language-route .language-edit {
    position: relative;
    padding: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    z-index: 3;
    border: 3px solid #5a4abd !important;
}

.Full-page .cv-right-dashboard .certificate-route .certification-edit {
    position: relative;
    padding: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    z-index: 3;
    border: 3px solid #5a4abd !important;
}

/* MOBILE RESUME PREVIEW */
.Full-page .cv-right-dashboard .mobile-resume-prev {
    flex: 1;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    padding: 30px 0;
    background-color: #f2f2f2;
    display: none;
}

.Full-page .cv-right-dashboard .cv-prev {
    width: 793.0078px;
}

/* RESPONSIVE CSS */
@media screen and (max-width: 1024px) {

    .Full-page {
        column-gap: 25px;
    }

    .Full-page .cv-right-dashboard {
        width: auto;
        flex: 1;
        column-gap: 25px;
    }

    .Full-page .cv-right-dashboard .cv-all-forms {
        padding: 30px;
    }
}

@media screen and (max-width: 768px) {
    .Full-page {
        column-gap: 0;
    }

    .Full-page .sidebar {
        background: linear-gradient(90deg, rgba(112, 92, 236, 1) 0%, rgba(31, 104, 232, 1) 100%);
        position: fixed;
        bottom: 0;
        display: flex;
        justify-content: space-evenly;
        z-index: 100;
        width: 100%;
        padding: 0;
    }

    .Full-page .sidebar .sidebar-icon {
        padding: 20px 0;
        border: none;
    }

    .Full-page .sidebar .sidebar-icon:hover {
        background-color: transparent;
    }

    .Full-page .sidebar .sidebar-icon svg {
        width: 22px;
        height: 22px;
        color: #fff;
    }

    .Full-page .prev-wrapper {
        width: 0%;
    }

    .Full-page .prev-wrapper .resume-prev-section .resumeCV {
        display: none;
    }

    .Full-page .prev-wrapper .resumecv-eye {
        position: fixed;
        z-index: 1000;
        right: 20px;
        bottom: 80px;
        border-radius: 50%;
        padding: 0;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Full-page .cv-right-dashboard {
        width: 100%;
        flex-wrap: nowrap;
        column-gap: 0;
    }

    .Full-page .cv-right-dashboard .cv-all-forms {
        flex: 1;
        height: 95vh;
        padding-bottom: 100px;
    }

    /* SHOW RESUME PREV ON POP */
    .Full-page .cv-right-dashboard .hide-form {
        display: none;
    }

    .Full-page .cv-right-dashboard .show-cv-prev {
        display: flex;
        zoom: 0.5;
    }
}

@media screen and (max-width: 450px) {
    .Full-page .cv-right-dashboard .show-cv-prev {
        zoom: 0.35;
    }
}