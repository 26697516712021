.MRT-Template {
    width: 100%;
    min-height: 1122.520px;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    background-size: cover;
    background-position: right;
}

.MRT-Template .resume-qr-code {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.MRT-Upper-section-content {
    padding: 2rem;
    display: flex;
    position: relative;
}

.MRT-Sec-img {
    width: 200px;
    height: 266px;
    border-top-left-radius: 60px;
    border-bottom-right-radius: 60px;
    margin-bottom: 2rem;
}

/* .MRT-sec-upper {
    width: 80%;
    display: flex;
    z-index: 10;
    justify-content: start;
    height: 6rem;
    margin-top: 15px;
    margin-left: .65%;
} */


.MRT-Heading-Style .hhh {
    letter-spacing: 4px;
    text-transform: uppercase;
    margin: 10px 0px 5px;
}

.MRT-Heading-Style .ppp {
    padding: 0 2rem 0.2rem 0.8rem;
}

.MRT-sec-details i,
.MRT-sec-details p {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.MRT-ul-details-sec {
    display: flex;
    width: 64%;
    flex-direction: column;
    position: absolute;
    right: 0;
    padding-right: 2rem;
    bottom: 10%;
}

.MRT-contact-details {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 3%;
    padding-left: 15px;
    row-gap: 5px;
    padding-bottom: 2px;
}

.MRT-contact-details i {
    margin-top: 4px;
}

.MRT-sec-details {
    display: flex;
    column-gap: 5px;
}

.MRT-Template .MRT-Right .first-sec h1 {
    font-size: 2.5rem;
    text-transform: uppercase;
    line-height: 1.2em;
}

.MRT-Template .MRT-Right .first-sec p {
    padding-bottom: 1rem;
    text-transform: uppercase;
    font-weight: bold;
}

.MRT-Lower-section {
    padding: 3rem;
    display: flex;
    column-gap: 4%;
}

.MRT-Left {
    width: 30%;
}

.MRT-Right {
    width: 66%;
    padding-bottom: 20px;
}

.MRT-common-list li {
    list-style-type: none;
    font-weight: 600;
    margin-bottom: 5px;
    display: flex;
    gap: 4px;

}

.MRT-common-list li:nth-last-child(1){
    margin-bottom: 0px;
}

.MRT-common-list li .list-dots::before{
    content: '>> ';
    padding-right: 4px;
}

.MRT-Heading-Style h4 {
    font-size: 14px;
    margin-bottom: 10px;
}

.MRT-mydiv {
    padding-left: 1rem;
    padding-bottom: 0.2rem;
}

.MRT-Template .common-section{
    padding-bottom: 10px;
}

.MRT-Template .common-section:nth-last-child(1){
    padding-bottom: 0px;
}