.month-year-picker {
  left: 0px !important;
}

.month-year-picker .year-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}

.month-year-picker .block-cursor {
  cursor: not-allowed !important;
}

.month-year-picker .year-controls i {
  padding: 10px 13px;
}

.month-year-picker .year-controls i:hover {
  font-weight: 800;
  background-color: #f2f2f2;
  color: #5A4ABD;
  font-weight: 600;
  border-radius: 50%;
  cursor: pointer;
}

.month-year-picker .year-controls .year-btn {
  color: rgb(0, 0, 0);
  font-size: 16px;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  background: none;
}

.month-year-picker .year-controls .year-btn:hover {
  background-color: #fafafa;
  border-radius: 50%;
}

.month-year-picker .year-controls .year-display {
  font-size: 18px;
  font-weight: bold;
}

.month-year-picker .month-grid {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5%;
  row-gap: 15px;
  width: 100%;
}

.month-year-picker .month {
  text-align: center;
  width: 30%;
  padding: 5px;
  background-color: white;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  border-radius: 20px;
  font-size: 12px;
}

.month-year-picker .month:hover {
  background-color: #fafafa;
  color: black;
  border: 1px solid #5a4abd;
}

.toggle-editable-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Montserrat";
  margin-bottom: 20px;
  border: 1px solid #e5e5e5;
  padding: 10px 15px;
  border-radius: 10px;
}

.toggle-editable-container .editable-text {
  border: none;
  outline: none;
  font-size: 14px;
  padding: 2px 5px;
  width: 100px;
  margin: 0;
  cursor: text;
}

.toggle-editable-container .editable-text:disabled {
  background: none;
  cursor: not-allowed;
  color: #a19999;
}

.toggle-editable-container .switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.toggle-editable-container .switch input {
  display: none;
}

.toggle-editable-container .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.toggle-editable-container .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.toggle-editable-container input:checked+.slider {
  background-color: #5A4ABD;
}

.toggle-editable-container input:checked+.slider:before {
  transform: translateX(14px);
}

@media screen and (max-width: 768px) {
  .month-year-picker {
    top: 40px;
    bottom: auto !important;
  }
}