.resume-wrapper {
    width: 793px; 
    border: 1px solid #e5e5e5;
  
}

.live-resume-preview-wrapper {
    display: flex;
    justify-content: center; 
    align-items: center; 
    width: 100%; 
    box-sizing: border-box; 
    padding: 60px 0;
}

@media screen and (max-width: 450px) {
    .resume-wrapper{
        zoom: 0.48;
    }
}