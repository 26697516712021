.SalesAchieverRT {
    position: relative;
    width: 100%;
    min-height: 1120px;
    background-color: #fff;
    background-size: cover;
    background-position: right;
}

.SalesAchieverRT .resume-qr-code {
    position: absolute;
    bottom: 10px;
    right: 10px;
    gap: 2%;
}

.SalesAchieverRT .parent-sec {
    display: flex;
    padding: 30px 0;
    width: 100%;
}

.SalesAchieverRT .left-sec {
    position: relative;
    min-width:30%;
    max-width:30%;
    padding: 25px;
    background-color: #f0f0f0;
    margin-left: 30px;
}

.SalesAchieverRT .left-sec:after {
    content: '';
    position: absolute;
    top: -8px;
    right: -30px;
    width: 0px;
    height: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-bottom: 40px solid #ffffff;
    z-index: 100;
    transform: rotate(45deg);
}

.SalesAchieverRT .right-sec {
    padding: 30px 0 30px 30px;
    width: 70%;
}

.SalesAchieverRT .for-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    border: 5px solid #fff;
    margin-bottom: 20px;
    margin-left: 15px;
   
}

.SalesAchieverRT .for-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.SalesAchieverRT h2 {
    margin: 10px 0px 5px;
    text-transform: uppercase;
}

.SalesAchieverRT h1 {
    font-size: 30px;
}

.SalesAchieverRT .left-sec .contact-list li {
    list-style: none;
    font-weight: 550;
    display: flex;
    gap: 10px;
    margin-bottom: 6px;
}
.SalesAchieverRT .left-sec .contact .contact-list:last-child{
    margin-bottom: 0px;
}

.SalesAchieverRT .left-sec .contact-list i {
    /* font-size: 12px; */
    margin-top: 4px;
}

.SalesAchieverRT .left-sec .contact-list ul a:nth-last-child(1) li {
    margin-bottom: 0px;
}

.SalesAchieverRT .sales-common-list-styling ul li {
    margin-bottom: 5px;
    display: flex;
    gap: 10px;
    font-weight: 550;
}

.SalesAchieverRT .sales-common-list-styling ul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.SalesAchieverRT .sales-common-list-styling ul li::before {
    content: "► ";
}

.SalesAchieverRT .parent-sec .right-sec .profile-details {
    margin-bottom: 10px;
    padding-right: 30px;
}

.SalesAchieverRT .right-sec .gradient-section {
    height: 2rem;
}

.SalesAchieverRT .parent-sec .right-sec .summary h2 {
    padding-top: 12px;
}

.SalesAchieverRT .right-sec .for-spacing {
    padding-right: 25px;
}

.SalesAchieverRT .parent-sec .right-sec .custom-common-details {
    padding-bottom: 5px;
}

.SalesAchieverRT .parent-sec .right-sec .custom-common-details:nth-last-child(1){
    padding-bottom: 0px;
}