.Border-Style-Resume-Template {
    width: 100%;
    min-height: 1120px;
    position: relative;
    padding: 30px;
    font-family: "Poppins", sans-serif;
    background-color: #fff;
    border: 8px solid;
    background-size: cover;
    background-position: right;
}

.Border-Style-Resume-Template .resume-qr-code {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.Border-Style-Resume-Template h4 {
    font-size: 14px;
    font-weight: 500;
}

/* HEADER CSS */

.Border-Style-Resume-Template .Header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}

.Border-Style-Resume-Template .Header .P-details {
    width: 70%;
    padding-bottom: 10px;
    border-bottom: 2px solid;
}

.Border-Style-Resume-Template .Header h1 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 5px;
    line-height: 1.1em;
}

/* IMAGE CSS */

.Border-Style-Resume-Template .Header .Profile_Image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 10px solid;
    background-size: cover;
    background-position: center;
}

.Border-Style-Resume-Template .Container {
    width: 100%;
    display: flex;
}

/* .Left-Column */

.Border-Style-Resume-Template .Container .Left-Column {
    width: 40%;
    padding-right: 30px;
}

.Border-Style-Resume-Template h2 {
    margin: 10px 0px 5px 0px;
    font-weight: 600;
    text-transform: uppercase;
}

/* .Left-Column .Contact-info */

.Border-Style-Resume-Template .Container .Left-Column .Contact-info ul li {
    list-style: none;
    margin-bottom: 8px;
    display: flex;
    column-gap: 10px;
}

.Border-Style-Resume-Template .Container .Left-Column .Contact-info ul a:nth-last-child(1) li {
    margin-bottom: 0px;
}

.Border-Style-Resume-Template .Contact-info {
    border-bottom: 2px solid;
    padding-bottom: 10px;
}

.Border-Style-Resume-Template .Container .Left-Column .Contact-info ul li i {
    font-size: 12px;
    margin-top: 4px;
}

/* .Left-Column .Skills-info */

.Border-Style-Resume-Template .Container .Left-Column .common-list-styling ul {
    border-left: 2px solid;
    padding: 10px 0 10px 20px;
    position: relative;
}

.Border-Style-Resume-Template .Container .Left-Column .common-list-styling ul li {
    list-style: none;
    margin-bottom: 6px;
}

.Border-Style-Resume-Template .Container .Left-Column .common-list-styling ul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.Border-Style-Resume-Template .Container .Left-Column .common-list-styling ul li span {
    width: 8px;
    height: 8px;
    position: absolute;
    margin-top: 5px;
    left: -2.1%;
    border-radius: 10px;
}

.Border-Style-Resume-Template .Container .Left-Column .common-list-styling {
    border-bottom: 2px solid;
    padding-bottom: 10px;
}

/* .Left-Column .Project-info */

.Border-Style-Resume-Template .Container .common-section .common-details {
    padding-bottom: 5px;
}

.Border-Style-Resume-Template .Container .common-section .common-details:nth-last-child(1) {
    padding-bottom: 0px;
}

.Border-Style-Resume-Template .Container .common-section {
    border-bottom: 2px solid;
    padding-bottom: 10px;
}

/* .Right-Column */

.Border-Style-Resume-Template .Container .Right-Column {
    width: 60%;
    border-left: 2px solid;
    padding-left: 30px;
    padding-bottom: 30px;
}

/* .Right-Column .Summary-info */

.Border-Style-Resume-Template .Container .Right-Column .Summary-info {
    border-bottom: 2px solid;
    padding-bottom: 10px;
}