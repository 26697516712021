.edit-contact-form form .social-media {
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    margin-bottom: 15px;
    position: relative;
}

.edit-contact-form form input {
    width: 100%;
    border: none;
    padding: 10px;
    font-family: "mulish";
}

.edit-contact-form form input:focus {
    outline: none;
}

.edit-contact-form form .social-media #brandIcon {
    padding: 10px;
    background-color: #fff;
    border-right: 1px solid #e5e5e5;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: pointer;
}

.edit-contact-form form .social-media #brandIcon:nth-last-child(1){
    border-right: none;
}

.edit-contact-form form .social-media #brandIcon-link {
    padding: 10px;
    background-color: #fff;
    border-left: 1px solid #e5e5e5;
    cursor: pointer;
}

.edit-contact-form .additional-link-sec {
    width: 360px;
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    right: 0px;
    animation: RightSliding 0.3s linear;
    transition: all 0.3s;
    z-index: 100;
    display: flex;
    column-gap: 10px;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 5px #ddd;
    color: #444;
    white-space: nowrap;
    bottom: 40px;
}

.edit-contact-form .additional-link-sec input {
    border: 1px solid #d6d6d6;
    border-radius: 10px !important;
}

.edit-contact-form .additional-link-sec button {

    padding: 10px 15px;
    border: none;
    color: #5a4abd;
    border-radius: 10px;
    font-family: "Montserrat";
    font-weight: 600;
}

.edit-contact-form .additional-link-sec p {
    font-family: 'mulish';
    font-size: 13px;
    color: #000;
}

.edit-contact-form form .social-media .fa-trash {
    border-radius: 10px;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-left: 1px solid #e5e5e5;
}

.edit-contact-form form .social-section .delete-section {
    display: flex;
    justify-content: flex-end;
}

.edit-contact-form form .social-section .delete-section i {
    padding: 10px;
    border-radius: px;
    background-color: #fff;
}


/* **** BUTTON STYLE **** */

.edit-contact-form form .save-section {
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
}

.edit-contact-form form .save-section a {
    padding: 10px;
    background: linear-gradient(90deg, rgba(112, 92, 236, 1) 0%, rgba(42, 81, 186, 1) 100%);
    text-decoration: none;
    color: white;
    border-radius: 10px;
    font-family: "montserrat";
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    border: none;
    cursor: pointer;
}

.edit-contact-form form .save-section a i {
    margin-left: 8px;
}

.edit-contact-form form .save-section button {
    min-width: calc(50% - 7.5px);
    padding: 10px;
    border: none;
    border-radius: 10px;
    color: #fff;
    background: linear-gradient(90deg, rgba(112, 92, 236, 1) 0%, rgba(42, 81, 186, 1) 100%);
    font-family: "montserrat";
    font-size: 15px;
    font-weight: 500;
    z-index: 0;
}

@media screen and (max-width: 450px) {
    .edit-contact-form .additional-link-sec {
        width: 290px;
    }
}