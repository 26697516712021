.resume-process-navbar {
    width: 100%;
    padding-top: 35px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    color: #000;
    position: sticky;
    top: 65px;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    z-index: 50;
    column-gap: 50px;
}

.resume-process-navbar a {
    text-decoration: none;
    color: #000;
    font-weight: 700;
    padding: 0 15px 15px 10px;
    font-size: clamp(12px, 1vw, 15px);
    display: flex;
    align-items: center;
    column-gap: 10px;
    position: relative;
}

.resume-process-navbar a:hover {
    color: #5a4abd;
}

.resume-process-navbar a:hover span {
    display: block;
}

.resume-process-navbar a:hover::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1.5px;
    background-color: #5A4ABD;
    animation: ScaleEffect 0.2s linear;
}

.resume-process-navbar a span {
    display: none;
}

.resume-process-navbar a svg {
    width: 18px;
    height: 18px;
}

.resume-process-navbar .active-page {
    color: #5A4ABD;
}

.resume-process-navbar .active-page span {
    display: block;
}

.resume-process-navbar .active-page svg {
    color: #5A4ABD;
}

.resume-process-navbar .active-page::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1.5px;
    background-color: #5A4ABD;
    animation: ScaleEffect 0.2s linear;
}

/* ERROR CHECKING */
.resume-process-navbar .ischeck {
    position: absolute;
    right: -5px;
    color: #38b000;
    border: 2px solid #deffc7;
    border-radius: 50%;
    font-size: 8px;
}

.resume-process-navbar .iserror {
    position: absolute;
    right: -5px;
    color: #d00000;
    border: 2px solid #ffdddd;
    border-radius: 50%;
    font-size: 8px;
}

.leftbar-resumedashboard-flex {
    display: flex;
}

.active {
    font-weight: bold;
    text-decoration: underline;
}

/* RESPONSIVE */
@media screen and (max-width: 1440px) {
    .resume-process-navbar {
        column-gap: 30px;
    }
}

@media screen and (max-width: 1024px) {
    .resume-process-navbar a svg {
        width: 15px;
        height: 15px;
    }
}

@media screen and (max-width: 768px) {
    .resume-process-navbar {
        width: auto;
        flex-direction: column;
        background-color: #fff;
        padding: 0;
        top: 0;
        justify-content: flex-start;
        border: none;
    }

    .resume-process-navbar a {
        padding: 20px;
        border-bottom: 1px solid #e5e5e5;
    }

    .resume-process-navbar a:hover span {
        display: none;
    }

    .resume-process-navbar a svg {
        width: 25px;
        height: 25px;
    }

    .resume-process-navbar a span {
        display: none;
    }

    .resume-process-navbar .active-page span {
        display: none;
    }

    .resume-process-navbar .active-page {
        background-color: #5a4abd;
    }

    .resume-process-navbar .active-page svg {
        color: #fff;
    }

    .resume-process-navbar .active-page::before {
        display: none;
    }
}