.Entry_Level_Tech_Professional_Resume {
    width: 100%;
    min-height: 1120px;
    position: relative;
    padding: 30px 0;
    background-color: #fff;
    background-size: cover;
    background-position: right;
}

.Entry_Level_Tech_Professional_Resume .resume-qr-code {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

/* All H2 Css */

.Entry_Level_Tech_Professional_Resume .Header h2 {
    margin: 10px 0px 10px;
    padding: 5px 30px;
    text-transform: uppercase;
}

/* Personal Information css */

.Entry_Level_Tech_Professional_Resume .Header .Personal_Details {
    text-align: center;
}

.Entry_Level_Tech_Professional_Resume .Header .Personal_Details h1 {
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.Entry_Level_Tech_Professional_Resume .Header .Personal_Details h4 {
    margin-bottom: 15px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 3px;
}

/* Contact Information css */

.Entry_Level_Tech_Professional_Resume .Header .Contact_Information ul li {
    list-style: none;
    margin-bottom: 6px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.Entry_Level_Tech_Professional_Resume .Header .Contact_Information ul a:nth-last-child(1) li{
    margin-bottom: 0px;
}

.Entry_Level_Tech_Professional_Resume .Header .Contact_Information i {
    padding-right: 10px;
    font-size: 12px;
}

/* SUMMARY */

.Entry_Level_Tech_Professional_Resume .summary p {
    margin: 0 15px;
}

/* Skills Information css */

.Entry_Level_Tech_Professional_Resume .common-list-styling ul li {
    list-style: none;
    width: 48%;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.Entry_Level_Tech_Professional_Resume .common-list-styling ul {
    display: flex;
    flex-wrap: wrap;
    row-gap: 6px;
    padding-left: 30px;
}

.Entry_Level_Tech_Professional_Resume .common-list-styling svg {
    margin-right: 8px;
    width: 13px;
    height: 13px;
}

/* Education Information Css */

.Entry_Level_Tech_Professional_Resume .Header .common-section .common-field {
    display: flex;
    justify-content: space-between;
}

.Entry_Level_Tech_Professional_Resume .Header .common-section .common-details {
    margin: 0px 30px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #D2E4E5;
}

.Entry_Level_Tech_Professional_Resume .Header .common-section .common-details:nth-last-child(1) {
    border: none;
    margin-bottom: 0px;
    padding: 0px;
}