.project-form form .project-form-datepic-monthdate-input {
    gap: 8px;
    width: 100%;
    font-family: "mulish", sans-serif;
}

.project-form-section-pic-date {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 15px;
    width: 100%;
}

.project-form .month-year-pickers {
    position: relative;
    width: 100%;
}

.project-form .project-form-section-pic-date .month-year-pickers .start-end-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    outline: none;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    width: 100%;
    font-family: "mulish", sans-serif;
    background-color: #fff;
    cursor: pointer;
    font-size: 14px;
}

.project-form-del-add-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.project-form-del-add-button i {
    background-color: #fff;
    color: #333;
    font-size: 15px;
    font-weight: 600;
    border-radius: 50%;
    padding: 10px 13px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.project-form .project-number-box .project-form-del-add-button {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.project-form .project-number-box:nth-last-child(1) .project-form-del-add-button {
    padding-bottom: 0;
}

.project-form-del-add-button hr {
    width: 93%;
    border: none;
    height: 1px;
    background-color: #ccd2f9;
}

.project-form .project-textarea-delete-section {
    position: relative;
}

@media screen and (max-width: 450px) {
    .project-form-section-pic-date {
        flex-wrap: wrap;
    }
}