@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

.upload-media-image {
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
    font-family: "inter";
    align-items: center;
}

.upload-media-image h2 {
    font-weight: 500;
    font-size: 20px;
}

.upload-media-image button{
    padding: 10px 25px;
    font-family: "inter";
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid rgba(37, 32, 70, 0.1);
    color: #000;
    font-weight: 500;
    transition: all 0.3s ease; 
}