.atsskeleton-circular {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.atsskeleton-circular .ats-list {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin: 5px 0;
}

.separator {
    height: 1px;
    background-color: #e5e5e5;
    margin: 0;
}

.atsskeleton-circular .sk-p {
    height: 10px;
    position: relative;
    background-color: #e1e1e1;
    border-radius: 8px;
}

.atsskeleton-circular .skp-2 {
    background-color: #ebebeb;
}

.atsskeleton-circular-content-a {
    width: 95%;
    position: relative;
    overflow: hidden;
}

.atsskeleton-circular-content-a::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 100%;
    box-shadow: 0 0 80px 20px #ffffff;
    animation: atsskeleton-circularSlide 0.9s infinite ease-in-out;
}

.atsskeleton-circular-content-b {
    width: 65%;
    position: relative;
    overflow: hidden;

}

.atsskeleton-circular-content-b::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 100%;
    box-shadow: 0 0 80px 20px #b8b8b8;
    animation: atsskeleton-circularSlideB 0.9s infinite ease-in-out;
}

.atsskeleton-circular-content-c {
    width: 80%;
    position: relative;
    overflow: hidden;
}

.atsskeleton-circular-content-c::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 100%;
    box-shadow: 0 0 80px 20px #d3d3d3;
    animation: atsskeleton-circularSlide 0.6s infinite ease-in-out;
}

.sks-circular {
    height: 100px;
    background-color: #ebebeb;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
}

.sks-circular::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200%;
    height: 200%;
    background: rgba(211, 211, 211, 0.7);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: circularSlide 1s infinite ease-in-out;
}

@keyframes circularSlide {
    0% {
        transform: translate(-150%, -50%);
    }

    100% {
        transform: translate(50%, -50%);
    }
}

@keyframes atsskeleton-circularSlide {
    0% {
        transform: translateX(-150%);
    }

    100% {
        transform: translateX(150%);
    }
}

@keyframes atsskeleton-circularSlideB {
    0% {
        transform: translateX(-150%);
    }

    100% {
        transform: translateX(150%);
    }
}



.ATS-sec {
    width: 100%;
    display: flex;
    column-gap: 30px;
    margin-bottom: 20px;
}

.sks-circular {
    width: 100px;
}

.ATS-sec-list {
    width: 50%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
}