.blue-header-resume-template {
    width: 100%;
    min-height: 1120px;
    position: relative;
    padding: 0px 30px 30px 30px;
    background-color: white;
    background-size: cover;
    text-align: left;
    background-position: right;
}

.blue-header-resume-template .resume-qr-code {
    position: absolute;
}

.blue-header-resume-template .personal-details {
    background-color: #CCE1ED;
    padding: 30px 0px 10px 30px;
    width: calc(100% + 60px);
    left: -30px;
    position: relative;
}

.blue-header-resume-template h1 {
    font-size: 30px;
    margin-bottom: 5px;
}


.blue-header-resume-template b {
    font-size: 12px;
    font-weight: 450;
}

.blue-header-resume-template .contact-details {
    background-color: #404040;
    padding: 10px 30px;
    width: calc(100% + 60px);
    left: -30px;
    position: relative;
}

.blue-header-resume-template .contact-details ul li {
    list-style: none;
    display: flex;
    font-weight: 500;
   
}
.blue-header-resume-template .contact-details ul li p{
    display: flex;
    align-items: center;
    font-weight: 450;
}
.blue-header-resume-template .contact-details ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 5%;
    row-gap: 15px;
}

.blue-header-resume-template .contact-details i {
    margin-right: 10px;
    font-size: 10px;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    padding: 12px;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.blue-header-resume-template h2 {
    /* font-size: 14px; */
    margin: 12px 0px 8px 0px;
}
/* 
.blue-header-resume-template p {
    font-size: 10px;
    line-height: 18px;
} */

.blue-header-resume-template .skills-information ul li {
    list-style: inside;
    width: 21.2%;
    font-weight: 450;
    text-indent: -1.5em;
    padding-left: 1.5em;
 
}

.blue-header-resume-template .skills-information ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5%;
    row-gap: 5px;

}

.blue-header-resume-template .hobbies-information ul li {
    list-style: inside;
    width: 21%;
    text-indent: -1.5em;
    padding-left: 1.5em;
    font-weight: 450;
 

}

.blue-header-resume-template .hobbies-information ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5%;
    row-gap: 5px;

}

.blue-header-resume-template .common-sec .common-details {
    width: 100%;
    display: flex;
    gap:3%;
    padding-bottom: 8px;
}

.blue-header-resume-template .common-sec .common-details .common-datime {
   width: 23%;
}
.blue-header-resume-template .common-sec .common-details .common-level{
    width: 74%;
}

.blue-header-resume-template .common-sec .common-details:nth-last-child(1) {
    padding-bottom: 0px;
}
.blue-header-resume-template .languages-information ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5%;
    row-gap: 5px;
}
.blue-header-resume-template .languages-information ul li {
    list-style: inside;
    width: 21%;
    text-indent: -1.5em;
    padding-left: 1.5em;
    font-weight: 450;


}