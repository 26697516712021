.modern-curriculum-resume-templates {
    width: 100%;
    min-height: 1120px;
    border-top: 40px solid transparent;
    border-image: linear-gradient(90deg, rgba(242, 242, 242, 1) 40%, #fff 40%);
    border-image-slice: 1;
    background-color: #fff;
    background-size: cover;
    position: relative;
    background-position: right;
}

.modern-curriculum-resume-templates .resume-qr-code {
    position: absolute;
}

/* GLOBAL CSS FOR THIS TEMPLATE */

.modern-curriculum-resume-templates h1 {
    color: #fff;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 800;
    margin-bottom: 2px;
}

.modern-curriculum-resume-templates h2 {
    color: #333;
    text-transform: uppercase;
    font-weight: 800;
    background-color: #fff;
    padding: 10px 10px 8px 10px;
    border-left: 10px solid;
    margin: 10px 0px;
}

.modern-curriculum-resume-templates h4 {
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}

.modern-curriculum-resume-templates p,
.modern-curriculum-resume-templates li {
    font-weight: 500;
}

.modern-curriculum-resume-templates .summary-section .each-module {
    margin-bottom: 8px;
}
.modern-curriculum-resume-templates .summary-section h2{
    margin-top: 0px;
}

.modern-curriculum-resume-templates .summary-section .each-module:nth-last-child(1) {
    margin-bottom: 0px;
}

/* PERSONAL DETAILS */

.modern-curriculum-resume-templates .personal-details {
    padding: 30px;
    background-color: #333;
    display: flex;
    justify-content: space-between;
}

.modern-curriculum-resume-templates .personal-details .profile-image {
    width: 150px;
    height: 150px;
    border: 6px solid;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
}

.modern-curriculum-resume-templates .personal-details .name-section {
    width: calc(57% + 30px);
}

/* CONTACT DETAILS */

.modern-curriculum-resume-templates .personal-details .contact-section {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
}

.modern-curriculum-resume-templates .personal-details .contact-section li {
    list-style: none;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.modern-curriculum-resume-templates .personal-details .contact-section li p {
    color: #fff;
}

.modern-curriculum-resume-templates .personal-details .contact-section li i {
    width: 16px;
    height: 16px;
    background-color: #fff;
    font-size: 9px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
}

.modern-curriculum-resume-templates .column-wrapper {
    display: flex;
}

/* LEFT COLUMN */

.modern-curriculum-resume-templates .left-column {
    width: 40%;
    min-height: calc(1120px - 250px);
    padding: 30px;
    background-color: #f2f2f2;
}

/* SKILLS */

.modern-curriculum-resume-templates .common-lists li {
    list-style: none;
    font-weight: 600;
    margin-bottom: 5px;
}

.modern-curriculum-resume-templates .common-lists li:nth-last-child(1){
    margin-bottom: 0px;
}

/* RIGHT COLUMN */

.modern-curriculum-resume-templates .right-column {
    width: 60%;
    padding: 30px;
}

.modern-curriculum-resume-templates .right-column h2 {
    background-color: #f2f2f2;
}

.modern-curriculum-resume-templates .right-column .job-exp-section h2{
    margin-top: 0px;
}

.modern-curriculum-resume-templates .common-sec .each-module{
    padding-bottom: 5px;
}

.modern-curriculum-resume-templates .common-sec .each-module:nth-last-child(1){
    padding-bottom: 0px;
}