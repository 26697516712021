.personal-details-upload-photo {
  display: flex;
  column-gap: 10px;
  margin-bottom: 15px;
}

.personal-details-upload-photo .up-img-type {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.personal-details-upload-photo .profileImage {
  height: 100px;
  width: 100px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  background-size: cover;
  background-position: center;
}

.personal-details-upload-photo p {
  font-family: "Inter";
  text-transform: uppercase;
  font-size: 12px;
  color: #333;
  font-weight: 600;
}

.personal-details-upload-photo .f-type {
  background-color: #fff;
  padding: 5px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-right: 10px;
}

.personal-details-upload-photo .photo-upload {
  background: linear-gradient(90deg, rgba(112, 92, 236, 1) 0%, rgba(42, 81, 186, 1) 100%);
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}

.personal-details-upload-photo input {
  display: none;
}