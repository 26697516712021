.cva-cta {
    width: 100vw;
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 80px;
}
.cva-cta .cta-abox {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 60px 0;
    width: 80%;
    border-radius: 15px;
    position: relative;
    background-color: #ECF2FF;
    max-width: 1400px;
}
.cva-cta .cta-abox p{
    text-align: center;
    width: 90%;
}
@media screen and (max-width: 1024px){
    .cva-cta .cta-abox {
        width: 90%;
        padding: 50px 0px;
    }
}
@media screen and (max-width: 768px){
    .cva-cta {
        padding-top: 60px;
    }
    .cva-cta .cta-abox {   
        padding: 40px 0px;
    }
}
@media screen and (max-width: 450px) {
    .cva-cta .cta-abox {
        width: 90%;
        padding: 20px;
    }
    .cva-cta {
        padding-top: 60px;
    }
}