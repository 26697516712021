.cg-post-page {
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
    font-family: "inter";
    align-items: center;
}
.cg-post-page h3{
font-weight: 500;
font-size: 20px;
}
.cg-post-page button {
    padding: 10px 25px;
    font-family: "inter";
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid rgba(37, 32, 70, 0.1);
    /* color: #252046; */
    color: #000;
    font-weight: 500;
    transition: all 0.3s ease; 
}
.cg-post-page button:hover{
    background-color: rgba(37, 32, 70, 0.15);
}

