.icon-popup input {
    width: 100%;
    background-color: #fff;
    padding: 8px;
    border-radius: 5px;
    font-style: italic;
    font-size: smaller;
    margin-bottom: 10px;
    border: 1px solid #e5e5e5 !important;
}

.icon-popup [type=text]:focus {
    border-color: #5a4abd !important;
    outline: none;
}

.icon-popup .icon-box {
    width: 100%;
    height: 200px;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    overflow-x: hidden;
    overflow-y: auto;
}

.icon-popup .icon-box::-webkit-scrollbar {
    width: 10px;
}

.icon-popup .icon-box i {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-size: 1.2em;
    border-radius: 50px;
    cursor: pointer;
}

.icon-box i:hover {
    background-color: #eaebef77;
    color: #5a4abd;
}

.icon-popup p {
    font-family: "mulish";
    font-size: small;
    text-align: center;
    color: #333;
}

@media screen and (max-width: 450px) {
    .icon-popup{
        width: 280px !important;
        left: 0 !important;
    }    
}