.loader-container{
    width: 100vw;
    height: 100vh;
    position: absolute;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: #fff; 
    position: relative;
    z-index: 1; 
    top: 45%;
    

}

.spinner {
    border: 8px solid rgba(255, 255, 255, 0.1); 
    border-left-color: #5a4abd; 
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

