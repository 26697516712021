.font-popup-box h4 {
    font-family: "inter";
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 600;
    color: rgb(92, 92, 92);
}

.font-popup-box ul {
    width: 100%;
    max-height: 300px;
    overflow: auto;
    border: 1px solid #e5e5e5;
    padding: 10px;
    border-radius: 10px 0px 0px 10px;
    margin-bottom: 15px;
}

.font-popup-box ul::-webkit-scrollbar {
    width: 10px;
    background-color: #e5e5e5;
}

.font-popup-box ul::-webkit-scrollbar-thumb {
    background-color: #CDCDCD;
}

.font-popup-box ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 500;
}