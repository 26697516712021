.ats-friendly-resume {
    width: 100%;
    min-height: 1120px;
    position: relative;
    background-color: white;
    background-size: cover;
    padding: 30px;
    text-align: left;
    background-position: right;
}

.ats-friendly-resume .resume-qr-code {
    position: absolute;
    right: 10px;
    top: 10px;
}

.ats-friendly-resume h1 {
    font-size: 30px;
    margin-bottom: 5px;
}

.ats-friendly-resume h4 { 
    margin-bottom: 10px;
    color: #3765e2;
}

.ats-friendly-resume h2 {
    margin: 10px 0px 10px 0px;
    border-bottom: 2px solid #e5e5e5;
    text-transform: uppercase;
}

.ats-friendly-resume .contact-details ul {
    display: flex;
    gap: 10px 30px;
    flex-wrap: wrap;
}

.ats-friendly-resume .contact-details ul li {
    list-style: none;
    display: flex;
    align-items: flex-start;
    font-weight: 500;
    align-items: center;
   
}

.ats-friendly-resume .contact-details i {
    margin-right: 6px;
    font-size: 13px;
    color: #3765e2;
    /* margin-top: 5px; */
}

.ats-friendly-resume .common-section .common-details {
    padding-bottom: 5px;
}

.ats-friendly-resume .common-section .common-details:nth-last-child(1) {
    padding-bottom: 0px;
    border-bottom: none;
}

.ats-friendly-resume .common-list-style ul li {
    list-style: none;
    position: relative;
    padding-left: 15px;
    font-weight: 500;
}

.ats-friendly-resume .common-list-style ul {
    display: flex;
    gap: 5px 30px;
    flex-wrap: wrap;
}

.ats-friendly-resume .common-list-style li .list-dots {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #3765e2;
    left: 0px;
    top: 7px;
}