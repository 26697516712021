.template-section {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0px;
}

.template-section .ts-row {
    max-width: 1400px;
    width: 50%;
}

.template-section .homemenu {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.template-section .homemenu .active-tab {
    color: #5A4ABD;
    border-color: #5A4ABD;
}

.template-section .homemenu li {
    margin: 0 5px;
    z-index: 10;
    list-style: none;
    font-family: 'Montserrat';
    color: #333;
    font-weight: 600;
    padding: 0 5px 5px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
}

.template-section .view-all-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 5px;
    font-family: "Mulish";
    text-decoration: none;
    font-size: 18px;
    font-weight: 700;
    color: #5A4ABD;
    padding-bottom: 10px;
    border-bottom: 1px solid #f0f2fd;
}

.template-section .view-all-btn svg {
    width: 20px;
    height: 20px;
}

.template-section .resume-templates {
    max-width: 1400px;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 2%;
    row-gap: 30px;
}

.template-section .resume-templates .template-box {
    background-color: #f0f2fd;
    width: 32%;
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;
    position: relative;
    user-select: none;
}

.template-section .resume-templates .template-box:hover {
    background-color: #e3e7fc;
}

.template-section .resume-templates .template-box .resume-preve {
    margin-bottom: 30px;
}

.template-section .resume-templates .template-box .resume-preve .cg-temp-loading{
    height: 600px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.template-section .resume-templates .template-box .resume-preve .cg-temp-loading img{
    width: 60px;
    box-shadow: none;
    animation: FadeIMG 2s ease-in-out infinite alternate;
}

.template-section .resume-templates .template-box .resume-preve img {
    width: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}


.template-section .resume-templates .template-box button {
    width: 48%;
    border-radius: 10px;
    border: none;
    padding: 10px;
    font-family: "Inter";
    font-size: 18px;
}

.template-section .resume-templates .template-box .prev-temp {
    margin-right: 4%;
    border: 1px solid #5a4abd;
    color: #5a4abd;
    background: transparent;

}

.template-section .resume-templates .template-box .prev-temp:hover {
    background: linear-gradient(90deg, rgba(112, 92, 236, 1) 0%, rgba(42, 81, 186, 1) 100%);
    border: 1px solid transparent;
    color: #fff;
}

.template-section .resume-templates .template-box .use-temp {
    background: linear-gradient(90deg, rgba(112, 92, 236, 1) 0%, rgba(42, 81, 186, 1) 100%);
    border: 1px solid transparent;
    color: #fff;
}

.template-section .resume-templates .template-box .use-temp:hover {
    border: 1px solid #5a4abd;
    background: transparent;
    color: #5a4abd;
}

/* RESPONSIVE */

@media screen and (max-width: 1440px) {
    .template-section .resume-templates .template-box .resume-preve {
        zoom: 0.44;
    }

    .template-section .ts-row {
        width: 65%;
    }
}

@media screen and (max-width: 1280px) {
    .template-section .resume-templates .template-box .resume-preve {
        zoom: 0.39;

    }

    .template-section .ts-row h2 {
        font-size: 35px;
    }
}

@media screen and (max-width: 1024px) {
    .template-section .resume-templates {
        column-gap: 4%;
        width: 90%;
    }

    .template-section .resume-templates .template-box .resume-preve {
        zoom: 0.47;
    }

    .template-section .ts-row {
        width: 75%;
    }

    .template-section .resume-templates .template-box {
        width: 48%;
    }

    .template-section .ts-row h2 {
        font-size: 35px;

    }
}

@media screen and (max-width: 768px) {
    .template-section .resume-templates {
        column-gap: 4%;
        zoom: 0.78;
    }

    .template-section .resume-templates .template-box {
        width: 48%;
    }

    .template-section {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .template-section .ts-row {
        width: 80%;
    }

    .template-section .homemenu li {
        font-size: 16px;
    }

    .template-section .view-all-btn {
        font-size: 18px;
        font-family: "montserrat";
    }
}

/* for mobile view */

@media screen and (max-width: 450px) {
    .template-section {
        padding: 60px 0;
    }

    .template-section .resume-templates {
        column-gap: 4%;
        width: 90%;
    }

    .template-section .ts-row {
        width: 80%;
    }

    .template-section .homemenu {
        width: 90%;
    }

    .template-section .resume-templates .template-box {
        zoom: 0.68;
    }

    .template-section .resume-templates .template-box .resume-preve {
        zoom: 0.37;
    }

    .template-section .homemenu li {
        font-size: 15px;
    }

    .template-section .homemenu li:first-child {
        display: none;
    }

    .template-section .resume-templates .template-box .prev-temp {
        font-size: 20px;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;

    }

    .template-section .resume-templates .template-box .use-temp {
        font-size: 18px;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .template-section .view-all-btn svg {
        width: 25px;
        height: 25px;
    }
}

/* ANIMATION */
@keyframes FadeIMG {
    from{
        opacity: 0.5;
    }
    to{
        opacity: 1;
    }
}