/* Component-specific styles */
.FThemeTemplate-Template {
  width: 100%;
  min-height: 1122.520px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  color: rgb(66, 66, 66);
  background-color: #fff;
  background-size: cover;
  position: relative;
  background-position: right;
}

.FThemeTemplate-Template .resume-qr-code {
  position: absolute;
}

.FThemeTemplate-for-bottom-border-only {
  width: 100%;
  padding: 1rem 2rem;
  background-color: #f4f4f4;
  border-bottom: 2px solid rgb(170, 166, 166);
}

.FThemeTemplate-padding {
  padding: 0 2rem 2rem 2rem;
}

/* Section A */


.FThemeTemplate-Section-A {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.FThemeTemplate-sub-section-A {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  flex-direction: column;
}

.FThemeTemplate-sub-section-A h4 {
  margin-top: 0;
  line-height: 1.5em;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}

.FThemeTemplate-sub-section-A h1 {
  line-height: 1em;
  margin-top: 1rem;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 30px;
}

.FThemeTemplate-sec-acc-FT {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FThemeTemplate-sec-acc-FT ul li {
  list-style: none;
}

.FThemeTemplate-account {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.FThemeTemplate-account ul a:nth-last-child(1) li {
  margin-bottom: 0px;
}

.FThemeTemplate-account p {
  margin-right: 10px;
  text-align: right;
  font-weight: 500;
}

.FThemeTemplate-account i {
  /* font-size: 13px; */
  margin-top: 4px;
}

/* Section B */


.FThemeTemplate-for-heading h2 {
  margin: 10px 0px 5px;
  border-bottom: 1px solid rgb(32, 32, 32);
  font-weight: 600;
  text-transform: uppercase;
}

/* Section C */

.FThemeTemplate-section-C {
  display: flex;
  column-gap: 5%;
}

.FThemeTemplate-sub-section-c-left {
  width: 61%;
}

.FThemeTemplate-sub-section-c-right {
  width: 34%;
  padding-bottom: 30px;
}

.FThemeTemplateCommon-list-style {
  display: flex;
  flex-wrap: wrap;
}

.FThemeTemplateCommon-list-style li {
  list-style-type: none;
  font-weight: 500;
  color: rgb(32, 32, 32);
  margin: 5px 10px 2px 0;
  position: relative;
  padding-left: 10px;
}

.FThemeTemplateCommon-list-style li::before {
  content: '*';
  padding-right: 5px;
  position: absolute;
  top: 2.5px;
  left: 0;
}

.FThemeTemplate-for-heading .common-section {
  padding-bottom: 5px;
}

.FThemeTemplate-for-heading .common-section:nth-last-child(1) {
  padding-bottom: 0px;
}