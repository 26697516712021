.Beige-resume-template {
    width: 100%;
    min-height: 1122.5px;
    background-color: #434343;
    position: relative;
    background-size: cover;
    background-position: right;
}

.Beige-resume-template .resume-qr-code {
    position: absolute;
    padding: 4px;
    background-color: #fff;
    padding-bottom: 0;
}

/* Section 1st */
.Beige-resume-template .Beige-a-Template,
.Beige-resume-template .Beige-b-Template {
    display: flex;
    justify-content: space-around;
}

.Beige-resume-template .Beige-name {
    background-color: #fff;
    border-top-right-radius: 60px;
    width: 50%;
}

.Beige-resume-template .Beige-name-data {
    padding-top: 2.5rem;
    text-align: center;
}

.Beige-resume-template .Beige-name-data h1 {
    font-weight: 700;
    font-size: 24px;
    color: #f36e45;
    line-height: 1.1em;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.Beige-resume-template .Beige-name-data h4 {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
}

.Beige-resume-template .Beige-contact-me {
    width: 50%;
    background-color: #434343;
}

.Beige-resume-template .Beige-contact-sec {
    padding: 2rem 0 2rem 2rem;
}

.Beige-resume-template .Beige-contact-sec h2 {
    margin: 10px 0px 5px;
}

.Beige-resume-template .Beige-contact {
    display: flex;
    align-items: center;
    padding-bottom: 0.3rem;
}
.Beige-resume-template .Beige-contact:first-child{
    padding-top: 0.1rem;
}
.Beige-resume-template .Beige-contact:last-child{
    padding: 0;
}
.Beige-resume-template .Beige-contact i {
    color: #f36e45;
    padding-right: 2%;
}

.Beige-resume-template .Beige-contact p {
    color: #fff;
    margin: 0 0 0 5px;
    font-weight: 500;
}

/* Section  2nd */
.Beige-resume-template .Beige-about-me {
    border-top-right-radius: 160px;
    border-bottom-right-radius: 160px;
    display: flex;
    padding: 20px;
    height: 100%;
}

.Beige-resume-template .Beige-about-me h2 {
    color: #fff;
    margin: 5px 0px 5px;
    text-transform: uppercase;
}

.Beige-resume-template .Beige-about-me p {
    color: #fff;
    width: 98%;
}

.Beige-resume-template .Beige-about-blank-div {
    width: 80%;
    height: auto;
    border-top: 30px solid #fff;
    border-bottom: 30px solid #fff;
    border-right: 30px solid #fff;
    border-top-right-radius: 160px;
    border-bottom-right-radius: 160px;
    background-color: #fff;
}

.Beige-resume-template .new-image {
    position: absolute;
}

.Beige-resume-template .Beige-profile-image {
    min-width: 120px;
    height: 120px;
    border-radius: 50%;
    background-size: cover;
    margin: auto;
    margin-right: 5px;

}

.Beige-resume-template .Beige-section-education {
    padding: 2rem;
    margin-bottom: 0px;
}

.Beige-resume-template .Beige-section-edu-heading h2 {
    color: #f36e45;
    text-transform: uppercase;
}

.Beige-resume-template .Beige-edu-grp {
    display: flex;
    color: #f36e45;
    gap: 2%;
    font-weight: 500;
    justify-content: space-between;
    width: 100%;
}
.Beige-resume-template .Beige-edu-grp .Bedg-a{
    width:70%;
}
.Beige-resume-template .Beige-edu-grp .Bedg-b{
    width: 28%;
    text-align: right;

}
.Beige-resume-template .Beige-edu-grp p {
    text-transform: uppercase;
}

.Beige-resume-template .beige-course-name {
    color: #fff;
}

.Beige-resume-template .Beige-lower-left {
    width: 50%;
    padding: 2rem;
    background-color: #F4F4F4;
    font-weight: 500;
    border-top-right-radius: 80px;
    /* margin-bottom: 10px; */
}

.Beige-resume-template .Beige-edu-grp+.beige-course-name+.beige-about-edu {
    color: #fff;
    font-weight: 500;
}

.Beige-resume-template .new-section-edu {
    width: 50%;
    background-color: #434343;
    display: flex;
    flex-direction: column;
}

.Beige-resume-template .Beige-common-list-styling {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    column-gap: 12px;
    row-gap: 4px;
}

.Beige-resume-template .Beige-hobbies-section li {
    color: #000000;
}

.Beige-resume-template .Beige-common-list-styling li {
    padding: 0;
    border-radius: 5px;
    text-transform: uppercase;
    list-style-type: none;
    color: #fff;
    position: relative;
    padding-left: 12px;
}

.Beige-resume-template .Beige-common-list-styling li::before {
    content: "•";
    font-size: 20px;
    padding-right: 8px;
    color: #f36e45;
    position: absolute;
    left: 0;
    top: -2px;
}

.Beige-resume-template .forHeading-beige h2 {
    margin: 10px 0px 5px;
    text-transform: uppercase;
    color: #f36e45;
}

.Beige-resume-template .Beige-skill-sec h2 {
    color: #f36e45;
    margin: 10px 0px 5px;
}

.Beige-resume-template .Beige-common-sections {
    padding-bottom: 5px;
}

.Beige-resume-template .Beige-common-sections:nth-last-child(1) {
    padding-bottom: 0px;
}

.Beige-resume-template .Beige-hobbies-section li{
    color: #000;
}