.ats-meter {
  width: 100%;
  background-color: #eee;
  border-radius: 5px;
  position: relative;
  margin-top: 10px;
}

.meter-bar {
  height: 20px;
  border-radius: 5px;
}

.score-text {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
}

.result-list-ats li {
  font-size: 14px;
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5;
}

.result-list-ats li:last-child {
  border-bottom: none;
}

.improvement-suggestions ul {
  list-style-type: disc;
  padding-left: 20px;
}
.ats-score-popup .error-message{
  display: flex;
  gap: 10px;
  font-family: Inter;
}
.ats-score-popup .error-message .iserror{
margin-top: 3px;
color: #d00000;
} 
.ats-score-popup .error-message p {
  font-size: 14px;
}

.improvement-suggestions h3 {
  font-size: 14px;
  font-family: "montserrat";
  margin-top: 10px;
  margin-bottom: 5px;
}

.improvement-suggestions p {
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5e5;
  font-family: "mulish";
  line-height: 1.3em;
  font-size: 13px;
  padding-right: 6px;
}

.improvement-suggestions p:last-child {
  border: none;
}

.improvement-suggestions h3:first-child {
  margin-top: 0px;
}

.improvement-suggestions {
  height: 210px;
  overflow-y: auto;
  margin-top: 20px;
}

.ats-score-popup {
  width: 400px;
}

@media screen and (max-width: 450px) {
  .ats-score-popup{
    width: 350px;
  }
}