.Full-page .cv-right-dashboard .cv-all-forms {
    flex: 1;
    border-left: 1px solid #e5e5e5;
    background-color: #F0F2FD;
    padding: 50px;
    height: 100vh;
    overflow-y: auto;
    position: relative;
}

.Full-page .cv-right-dashboard .cv-all-forms .ql-editor {
    font-family: "Mulish";
    font-size: 14px;
    line-height: 1.3rem;
}

/* FORM FILLED BUTTON DESIGN */
.Full-page .cv-right-dashboard .cv-all-forms .cg-web-form-fill-btn {
    width: 100%;
    color: white;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    padding: 10px 0;
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(112, 92, 236, 1) 0%, rgba(42, 81, 186, 1) 100%);
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}

.Full-page .cv-right-dashboard .cv-all-forms .cg-web-form-fill-btn:hover {
    background: linear-gradient(90deg, rgba(42, 81, 186, 1) 0%, rgba(112, 92, 236, 1) 100%);
}

.Full-page .cv-right-dashboard .cv-all-forms .add-section {
    margin-bottom: 15px;
}

/* ALL FORM GLOBAL STYLES  */
.Full-page .cv-right-dashboard .cv-all-forms .cg-form-h2 {
    font-family: "Montserrat";
    font-size: 30px;
    margin-bottom: 30px;
    color: #000;
}

.Full-page .cv-right-dashboard .cv-all-forms .cg-wrap-inputs {
    position: relative;
    margin-bottom: 20px;
}

.Full-page .cv-right-dashboard .cv-all-forms .cg-wrap-inputs .cg-form-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    color: #333;
    font-family: "Mulish";
    font-weight: 500;
}

.Full-page .cv-right-dashboard .cv-all-forms .cg-wrap-inputs .cg-form-input:nth-last-child(1) {
    margin-bottom: 0;
}

.Full-page .cv-right-dashboard .cv-all-forms .cg-wrap-inputs .cg-form-input:focus {
    outline: none;
    color: #000;
}

/* FORM TEXT EDITOR */
.Full-page .cv-right-dashboard .cv-all-forms .cg-wrap-inputs .cg-text-editor {
    position: relative;
}

.Full-page .cv-right-dashboard .cv-all-forms .cg-wrap-inputs .cg-text-editor .cg-ai-text-suggestion {
    column-gap: 10px;
    color: #fff;
    border-radius: 20px;
    padding: 5px 20px;
    font-family: "Inter";
    font-size: 14px;
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    background: linear-gradient(90deg, #4285f4 0, #9b72cb 30%, #d96570 80%);
    background-size: 200% 100%; 
    animation: moveBackground 5s linear infinite; 
    display: flex;
}

@keyframes moveBackground {
    0% {
        background-position: 100% 0; 
    }
    50% {
        background-position: 0 0;  
    }
    100% {
        background-position: 100% 0;  
    }
}

.Full-page .cv-right-dashboard .cv-all-forms .cg-wrap-inputs .cg-text-editor .cg-ai-text-suggestion .ai-text-animated-line {
    display: flex;
    column-gap: 10px;
}



.cg-ai-text-suggestion::after {
    content: '';
    position: absolute;
    top: 50%; 
    left: -100%; 
    height: 120%; 
    width: 4px; 
    background-color: rgba(255, 255, 255, 0.5); 
    transform: translateY(-50%) rotate(30deg); 
    animation: lineAnimation 2s linear infinite; 
    z-index: 0;
    filter: blur(2px); 
    opacity: 0.8; 
    box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0.5);
}

.fa-sparkles {
    margin-top: 1px;
    animation: scaleAnimation 2s infinite ;
}

@keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);  
    }
    100% {
        transform: scale(1); 
    }
}
@media screen and (max-width: 450px) {
    .Full-page .cv-right-dashboard .cv-all-forms .cg-wrap-inputs .cg-text-editor .cg-ai-text-suggestion p {
        display: none;
    }
    .Full-page .cv-right-dashboard .cv-all-forms .cg-wrap-inputs .cg-text-editor .cg-ai-text-suggestion{
        color: #ffffff;

        padding: 5px 10px;
        border-radius: 10px;
    }
}