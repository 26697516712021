.creative-image-template {
    width: 100%;
    min-height: 1120px;
    position: relative;
    display: flex;
    background-color: white;
    background-size: cover;
    background-position: right;
}

.creative-image-template .left-column {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.creative-image-template .resume-qr-code {
    position: absolute;
}

/* image css  */

.creative-image-template .left-column .profile-img {
    /* width: 100%; */
    min-height: 300px;
    background-position: center;
    background-size: cover;
}

/* right column global Css */

.creative-image-template .left-column .sub-left {
    background-color: #28555E;
    padding: 30px;
    flex: 1;
}

.creative-image-template .left-column h2 {
    margin: 10px 20px 5px 0px;
    text-align: left;
    text-transform: uppercase;
}

.creative-image-template h2 i {
    margin-right: 10px;
    color: #d18c5c;
}


/* contact details Css */

.creative-image-template .left-column .sub-left .contact-details h2{
    margin-top: 0px;
    margin-bottom: 10px;
}
.creative-image-template .left-column .sub-left .contact-edit {
    padding-bottom: 2px;
}
.creative-image-template .left-column .sub-left .contact-details ul li {
    list-style: none;
    margin-bottom: 8px;
    text-align: left;
    display: flex;
    align-items: flex-start;
    column-gap: 15px;
}
.creative-image-template .left-column .sub-left .contact-details ul li:last-child{
    margin-bottom: 0;
}
.creative-image-template .left-column .sub-left .contact-details ul li:first-child{
    margin-bottom: 8px;
}
.creative-image-template .left-column .sub-left .contact-details ul i {
    font-size: 10px;
    border-radius: 50%;
    color: #000000;
    min-width: 18px;
    min-height: 18px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    margin-top: 2px;
}

.creative-image-template .left-column .sub-left .contact-details ul a:nth-last-child(1) li {
    margin-bottom: 0px;
}



/* Skills Information CSS */
.creative-image-template .left-column .common-list-styling ul {
    padding-left: 4px;
}
.creative-image-template .common-list-styling ul li {
    font-size: 12px;
    list-style: none;
    margin-bottom: 4px;
    position: relative;
    padding-left: 20px;
}

.creative-image-template .common-list-styling ul li:nth-last-child(1){
    margin-bottom: 0px;
}

.creative-image-template .common-list-styling .list-dots {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    bottom: 10px;
    top: 6px;
    left: 0px;
}

/* Right column global css */

.creative-image-template h1 {
    font-size: 40px;
    margin-bottom: 5px;
    line-height: 45px;
}

.creative-image-template h4 {
    font-size: 14px;
    margin-bottom: 30px;
}

.creative-image-template .right-column {
    width: 55%;
    margin: 30px;
    text-align: left;
}

.creative-image-template .right-column h2 {
    color: rgb(0, 0, 0);
    margin: 10px 0px 5px 0px;
    text-align: left;
    text-transform: uppercase;
}


/* common CSS */


.creative-image-template .common-section .common-details i {
    color: #d18c5c;
}

.creative-image-template .common-section .common-details {
    padding-bottom: 5px;
}

.creative-image-template .common-section .common-details:nth-last-child(1) {
    padding-bottom: 0px;
}