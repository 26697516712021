.htd-section {
    width: 100vw;
    padding: 80px 0px;
    background-color: #5a4abd;
    display: flex;
    justify-content: center;
}

.htd-section .htd-row {
    max-width: 1400px;
    width: 80%;
    display: flex;
    column-gap: 28%;
    position: relative;
}

.htd-section .htd-row .arrow {
    position: absolute;
    top: 35%;
    left: 36%;
    transform: rotateX(180deg);
}

.htd-section .htd-row .column {
    width: 47%;
}


.htd-section .htd-row li {
    font-family: "inter";
    display: flex;
    align-items: flex-start;
    font-size: 18px;
    color: #fff;
    margin-bottom: 15px;
    font-weight: 500;
    column-gap: 10px;
}

.htd-section .htd-row li i{
    color: #5a4abd;
    min-width: 18px;
    min-height: 18px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
}

.htd-section .htd-row button {
    background-color: #fff;
    border: none;
    padding: 12px 60px;
    font-size: 18px;
    font-weight: 500;
    margin-top: 15px;
    color: #5a4abd;
    font-family: "Inter";
    border-radius: 10px;
}

.htd-section .htd-video-container {
    zoom: 0.5;
    border-radius: 25px;
    box-shadow: 20px 20px 30px #8478CE,
    30px 30px 8px #E3E7FC;
}

.htd-section .htd-row-mobile {
    display: none;
}



@media screen and (max-width: 1440px){
    .htd-section .htd-row .arrow {
        left: 36%;
        width: 250px;
    }
    .htd-section .htd-row {
        column-gap: 20%;
      
    }
}
@media screen and (max-width: 1280px){
    .htd-section .htd-row .arrow {
        top: 30%;
        left: 34%;
        width: 200px;
    }
    .htd-section .htd-row {
        column-gap: 9%;
      
    }
}
@media screen and (max-width: 1024px){
    .htd-section .htd-row {
        width: 90%;
        column-gap: 15%;
        
    }
    .htd-section .htd-row li {
        font-size: 16px;
        margin-bottom: 10px;
        column-gap: 8px;
        
    }
    .htd-section .htd-row li i{
        max-width: 18px;
        max-height: 18px;
        font-size: 12px;
        border-radius: 50%;
    }
    .htd-section .htd-row .arrow {
        position: absolute;
        top: 35%;
        left: 32%;
        transform: rotateX(180deg);
        width: 200px;
    }
    .htd-section .htd-row .column {
        width: 40%;
    }
    .htd-section .htd-video-container {
        zoom: 0.45;
    }
}

@media screen and (max-width: 768px) {

    .htd-section {
        padding: 60px 0;
    }

    .htd-section .htd-row {
        display: none;
    }

    .htd-section .htd-row-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        width: 90%;
    }



    .htd-row-mobile .rm-feature {
    
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
    }

    .htd-row-mobile .rm-feature li {
        width: 100%;
        display: flex;
        column-gap: 8px;
        font-family: "inter";
        row-gap: 10px;
        padding-bottom: 8px;
        align-items: center;
        font-size: 14px;
      
    }

    .htd-row-mobile .rm-feature li i{
        background: white;
        border-radius: 50%;
        min-width: 16px;
        min-height: 16px;
        color: #5a4abd;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
       text-align: center;
    }
    .htd-row-mobile .rm-feature li:last-child {
        width: 90%;
    }

    .htd-row-mobile .rm-video {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin-top: 20px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .htd-row-mobile .rm-video button {
        background-color: #fff;
        border: none;
        padding: 12px 60px;
        font-size: 18px;
        font-weight: 500;
        font-family: "inter";
        border-radius: 10px;
        color: #5a4abd;
        margin-top: 20px;
    }

    .htd-section .htd-video-container {
        width: 60%;
    }

    .htd-section .htd-video-container {
        margin-top: 0px;
    }
}


@media screen and (max-width:450px) {
    .htd-section {
        padding: 60px 0;
    }

    .htd-section .htd-row {
        display: none;
    }

    .htd-section .htd-row-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        width: 90%;
    }



    .htd-row-mobile .rm-feature {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
    }

    .htd-row-mobile .rm-feature li {
        width: 100%;
        display: flex;
        column-gap: 8px;
        font-family: "inter";
        row-gap: 10px;
        padding-bottom: 8px;
        align-items: center;
        font-size: 14px;
        
    }

    .htd-row-mobile .rm-feature li i{
        background: white;
        border-radius: 50%;
        min-width: 16px;
        min-height: 16px;
        color: #5a4abd;
        font-size: 12px;
        align-items: center;
        text-align: center;
    }
    .htd-row-mobile .rm-feature li:last-child {
        width: 90%;
    }

    .htd-row-mobile .rm-video {
        width: 70%;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin-top: 20px;
        justify-content: center;
        align-items: center;
    }

    .htd-row-mobile .rm-video button {
        background-color: #fff;
        border: none;
        padding: 12px 30px;
        font-size: 18px;
        font-weight: 500;
        font-family: "Mulish";
        border-radius: 10px;
        color: #5a4abd;
    }

    .htd-section .htd-video-container {
        width: 100%;
    }

    .htd-section .htd-video-container {
        margin-top: 0px;
    }

}