.cg-media-edit-popup {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: right;
    z-index: 1000;
}

.cg-media-edit-popup .popup-container {
    background-color: #fff;
    border-radius: 20px;
    width: 45%;
    padding: 30px;
    margin-top: 80px;
    margin-right: 50px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    max-height: 718px;
}

.cg-media-edit-popup .popup-container .media-popup-header {
    margin-bottom: 20px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.cg-media-edit-popup .popup-container .media-popup-header h2 {
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: #000;
}

.cg-media-edit-popup .popup-container .media-popup-header .cancel-btn {
    width: 22px;
    height: 22px;
    color: #000;
    cursor: pointer;
}

.cg-media-edit-popup .popup-container .media-popup-header .cancel-btn:hover {
    color: #5a4abd;
    animation: AnimOnCancelBtn 0.2s linear forwards alternate;
}

.cg-media-edit-popup .popup-container .cg-media-edit-preview {
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 15px;
}

.cg-media-edit-popup .popup-container .cg-tad-edit {
    margin-bottom: 15px;
}

.cg-media-edit-popup .popup-container .cg-tad-edit #banned {
    cursor: not-allowed;
}

.cg-media-edit-popup .popup-container .cg-tad-edit textarea[type='text'] {
    height: 60px;
    width: 100%;
    outline: none;
    border: 1px solid #e5e5e5;
    background-color: #fafafa;
    border-radius: 10px;
    padding: 10px;
    margin: 5px 0 10px;
    font-family: inter;
}

.cg-media-edit-popup .popup-container .cg-tad-edit input[type='text'] {
    width: 100%;
    padding: 10px;
    border: 1px solid #e5e5e5;
    background-color: #fafafa;
    border-radius: 10px;
    font-family: inter;
    color: #333;
    margin: 5px 0 10px;
    outline: none;
}

.cg-media-edit-popup .popup-container H4 {
    font-family: "Inter";
    font-size: 12px;
    text-transform: uppercase;
    color: #333;
    font-weight: 500;
    float: left;
}

.cg-media-edit-popup .popup-container .cg-media-del-save {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

.cg-media-edit-popup .popup-container .cg-media-del-save .media-del {
    color: #fff;
    background-color: #ff4d4d;
    width: 48%;
    border-radius: 10px;
    border: none;
    padding: 10px;
    font-family: "Inter";
    font-size: 18px;
}

.cg-media-edit-popup .popup-container .cg-media-del-save .media-del:hover {
    background-color: #e60000;

}

.cg-media-edit-popup .popup-container .cg-media-del-save .media-save {
    background-color: #50497a;
    color: #fff;
    width: 48%;
    border-radius: 10px;
    border: none;
    padding: 10px;
    font-family: "Inter";
    font-size: 18px;
}

.cg-media-edit-popup .popup-container .cg-media-del-save .media-save:hover {
    background-color: #252046;
}