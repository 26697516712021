.hundred-percent-professional-template {
    width: 100%;
    min-height: 1120px;
    position: relative;
    padding: 20px 30px 30px 30px;
    text-align: left;
    background-color: white;
    background-size: cover;
    background-position: right;
}

.hundred-percent-professional-template .resume-qr-code {
    position: absolute;
}

.hundred-percent-professional-template h1 {
    font-size: 30px;
    margin-bottom: 5px;
}

.hundred-percent-professional-template h4 {
    font-size: 14px;
    margin-bottom: 10px;
}

.hundred-percent-professional-template h2 {
    font-size: 14px;
    margin: 10px 0px 10px 0px;
    background-color: #DFD7D3;
    padding: 7px 10px 5px 10px;
    width: 70%;
    text-transform: uppercase;
}



.hundred-percent-professional-template b {
    font-size: 12px;
}

.hundred-percent-professional-template .contact-details ul {
    display: flex;
    gap: 10px 15px;
    flex-wrap: wrap;
}

.hundred-percent-professional-template .contact-details ul li {
    list-style: none;
    font-weight: 500;
    display: flex;
    align-items: center;

}

.hundred-percent-professional-template .contact-details i {
    margin-right: 10px;
    font-size: 12px;
    border-radius: 50%;
    color: #000;
    font-family: 'FontAwesome';

}

.hundred-percent-professional-template .main-section {
    display: flex;
    gap: 3%;
    border-top: 2px solid #DFD7D3;
    padding-top: 20px;
    margin-top: 5px;
}

.hundred-percent-professional-template .main-section .left-column {
    width: 40%;
}

.hundred-percent-professional-template .profile-img {
    width: 100%;
    height: 250px;
    background-position: center;
    background-size: cover;
    margin-bottom: 20px;
}

.hundred-percent-professional-template .main-section .left-column .education-information .education-details {
    padding-bottom: 10px;
}

.hundred-percent-professional-template .main-section .left-column .education-information .education-details:nth-last-child(1) {
    padding-bottom: 0px;
}


.hundred-percent-professional-template .skill-information ul li {
    list-style: disc;
    list-style: inside;
    margin-bottom: 8px;
    font-weight: 500;
    text-indent: -1.5em;
    padding-left: 1.5em;

}

.hundred-percent-professional-template .skill-information ul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.hundred-percent-professional-template .hobbies-information ul li {
    list-style: disc;
    list-style: inside;
    margin-bottom: 8px;
    text-align: left;
    font-weight: 500;
    text-indent: -1.5em;
    padding-left: 1.5em;
}

.hundred-percent-professional-template .hobbies-information ul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.hundred-percent-professional-template .main-section .right-column {
    width: 57%;
}

.hundred-percent-professional-template .main-section .right-column .summary-information h2 {
    margin-top: 0px;
}

.hundred-percent-professional-template .main-section .right-column .common-section .common-details {
    padding-bottom: 10px;
}

.hundred-percent-professional-template .main-section .right-column .common-section .common-details:nth-last-child(1) {
    padding-bottom: 0px;
}

.hundred-percent-professional-template .main-section .right-column .common-section .common-details .common-level {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 3%;
}

.hundred-percent-professional-template .main-section .right-column .common-section .common-details .common-level #cls-1 {
    width: 65%;
}

.hundred-percent-professional-template .main-section .right-column .common-section .common-details .common-level #cls-2 {
    width: 42%;
    text-align: right;
}


.hundred-percent-professional-template .main-section .right-column .languages-information ul li {
    list-style: disc;
    list-style: inside;
    text-indent: -1.5em;
    padding-left: 1.5em;
    margin-bottom: 8px;
    text-align: left;
    font-weight: 500;
}

.hundred-percent-professional-template .main-section .right-column .languages-information ul li:nth-last-child(1) {
    margin-bottom: 0px;
}