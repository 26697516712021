.blue-ats-proof-template {
    width: 100%;
    min-height: 1120px;
    position: relative;
    padding: 30px;
    text-align: left;
    background-color: white;
    background-size: cover;
    background-position: right;
}

.blue-ats-proof-template .resume-qr-code {
    position: absolute;
}

.blue-ats-proof-template h1 {
    font-size: 30px;
    margin-bottom: 5px;
}

.blue-ats-proof-template h4 {

    margin-bottom: 8px;
    color: #215399;
}

.blue-ats-proof-template h2 {
    font-size: 14px;
    margin: 10px 0px 10px 0px;
    border-bottom: 2px solid #7B9DD5;
    text-transform: uppercase;
}

.blue-ats-proof-template .contact-details {
    background-color: #E4F2FD;
    padding: 10px 20px;
}

.blue-ats-proof-template .contact-details ul li {
    list-style: none;
    display: flex;
    align-items: center;
    font-weight: 500;
}


.blue-ats-proof-template .contact-details ul {
    display: flex;
    gap: 10px 30px;
    flex-wrap: wrap;
}

.blue-ats-proof-template .contact-details i {
    margin-right: 6px;
    font-size: 13px;
    border-radius: 50%;

}

.blue-ats-proof-template b {
    font-size: 14px;
}

.blue-ats-proof-template .common-section .common-details {
    display: flex;
    justify-content: space-between;
}

.blue-ats-proof-template .common-section .common-details i {
    background-color: #E4F2FD;
    padding: 5px 10px;
    font-weight: 500;
}

.blue-ats-proof-template .common-level {
    padding-bottom: 6px;
}

.blue-ats-proof-template .common-level:nth-last-child(1) {
    padding-bottom: 0px;
}

.blue-ats-proof-template .skills-information ul li {
    list-style: none;

    padding: 3px 8px;
    font-weight: 500;
}

.blue-ats-proof-template .skills-information ul {
    display: flex;
    gap: 10px 30px;
    flex-wrap: wrap;
}

.blue-ats-proof-template .hobbies-information ul li {
    list-style: none;
    border: 1px solid #b6e3ff;
    padding: 3px 8px;
    font-weight: 500;
}

.blue-ats-proof-template .hobbies-information ul {
    display: flex;
    gap: 10px 30px;
    flex-wrap: wrap;
}

.blue-ats-proof-template .languages-information ul li {
    list-style: none;
    border: 1px solid #b6e3ff;
    padding: 3px 8px;
    font-weight: 500;
}

.blue-ats-proof-template .languages-information ul {
    display: flex;
    gap: 10px 30px;
    flex-wrap: wrap;
}