.language-form .language-list {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;
}

.language-form .language-list input {
  margin: 0px;
  padding: 10px;
  width: 100%;
  font-family: "mulish";
  border: none;
  outline: none;
  border-radius: 10px;
  width: 70%;
  border: 1px solid #e5e5e5;
}

.language-form .language-list .flex-row {
  display: flex;
  width: 50%;
  gap: 20px;
}

.language-form .language-list .flex-row #list-item-language {
  position: relative;
  margin: 0px;
  padding: 10px;
  width: 100%;
  font-family: "mulish";
  border: none;
  outline: none;
  border-radius: 10px;
  width: 100%;
  background-color: #fff;
}

.language-form .language-list .flex-row .wrapper-select {
  background: #fff;
  border-radius: 10px;
  padding-right: 15px;
  cursor: pointer;
  width: 100%;
  border: 1px solid #e5e5e5;
}

.language-form .language-list .fa-trash {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 10px 12px;
  cursor: pointer;
  background-color: #ffffff;
}

/* responsive */
@media screen and (max-width:450px) {
  .language-form .language-list {
    flex-wrap: wrap;
  }

  .language-form .language-list input {
    width: 100%;
  }

  .language-form .language-list .flex-row {
    width: 100%;
  }
}