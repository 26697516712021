.ppvp-resume-temp {
    width: 100%;
    min-height: 1120px;
    padding: 30px;
    background-color: #fff;
    position: relative;
    background-size: cover;
    background-position: right;
}

.ppvp-resume-temp .resume-qr-code {
    position: absolute;
}

.ppvp-resume-temp .ppvp-left-section .Pic {
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: -70px;
    border-radius: 50%;
    left: 110px;
    border: 10px solid #63769B;
}

.ppvp-resume-temp h2 {
    position: relative;
    margin: 10px 0px 8px 0px;
}

.ppvp-resume-temp h4 {
    font-size: 14px;
}

.ppvp-resume-temp .ppvp-right-section h2 {
    display: inline-block;
    background-color: #63769B;
    border-radius: 8px;
    padding: 5px 10px;
    margin: 10px 0px;
}

.ppvp-resume-temp h1 {
    text-transform: uppercase;
    font-size: 35px;
}

.ppvp-resume-temp .ppvp-left-section h2 .designing-line {
    position: absolute;
    content: " ";
    height: 10px;
    width: 10px;
    background-color: #63769B;
    border-radius: 50%;
    left: -21px;
    top: 5px;
}

.ppvp-resume-temp .ppvp-left-right-section {
    display: flex;
    column-gap: 5%;
    width: 100%;
}

.ppvp-resume-temp .ppvp-left-section {
    width: 35%;
    border-radius: 8px;
    background-color: #323B4C;
}

.ppvp-resume-temp .psc-section {
    position: relative;
    margin: 80px 0px 0px 35px;
    border-top: 2px solid #63769B;
    border-left: 2px solid #63769B;
    border-radius: 80px 0px 0px 0px;
    padding: 100px 15px 0px 15px;
}

.ppvp-resume-temp .ppvp-right-section {
    background-color: #fff;
    width: 60%;
}

.ppvp-resume-temp .contact-section ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: 8px;
}

.ppvp-resume-temp .contact-section li {
    display: flex;
    column-gap: 10px;
}

.ppvp-resume-temp .contact-section i {
    font-size: 12px;
    margin-top: 5px;
}

.ppvp-resume-temp img {
    position: absolute;
    left: 110px;
    border-radius: 50%;
    border: 12px solid #63769B;
    top: -69px;
}

.ppvp-resume-temp .skills-section ul {
    list-style-type: none;
}

.ppvp-resume-temp .skills-section li {
    position: relative;
    display: flex;
    column-gap: 10px;
    margin-bottom: 2px;
    align-items: flex-start;
}

.ppvp-resume-temp .skills-section li .list-dots {
    min-width: 6px;
    min-height: 6px;
    border-radius: 50%;
    /* bottom: 10px; */
    /* top: 6px; */
    left: 0px;
    background-color: #fff;
    margin-top: 7px;
}

.ppvp-resume-temp .hobbies-information ul {
    list-style-type: none;
}

.ppvp-resume-temp .hobbies-information li {
    position: relative;
    display: flex;
    margin-bottom: 2px;
    column-gap: 10px;
    align-items: center;
}

.ppvp-resume-temp .hobbies-information li .list-dots {
    min-width: 6px;
    min-height: 6px;
    border-radius: 50%;
    bottom: 10px;
    top: 6px;
    left: 0px;
    background-color: #fff;
}

.ppvp-resume-temp .personal-details {
    margin: 50px 0px 30px 50px;
    display: flex;
    flex-direction: column;
}


.ppvp-resume-temp .project-details {
    padding-bottom: 8px;
}

.ppvp-resume-temp .project-details:nth-last-child(1) {
    padding-bottom: 0px;
}

.ppvp-resume-temp .common-details {
    padding-bottom: 5px;
}

.ppvp-resume-temp .common-details:nth-last-child(1) {
    padding-bottom: 0px;
}

.ppvp-resume-temp .languages-information ul {
    list-style-type: none;
}

.ppvp-resume-temp .languages-information li {
    position: relative;
    display: flex;
    margin-bottom: 2px;
    column-gap: 10px;
    align-items: center;
}

.ppvp-resume-temp .languages-information li .list-dots {
    min-width: 6px;
    min-height: 6px;
    border-radius: 50%;
    bottom: 10px;
    top: 6px;
    left: 0px;
    background-color: #000;
}