.cg-admin-login-body{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
}

.cg-admin-login-body img{
    width: 60px;
    margin-bottom: 30px;
    cursor: pointer;
}

.cg-admin-login-body .cg-admin-login-box{
    width: 350px;
    padding: 20px;
    border: 1px solid #e5e5e5;
    background-color: #fafafa;
}

.cg-admin-login-body .cg-admin-login-box label{
    font-family: "Inter";
    font-size: 12px;
    text-transform: uppercase;
    color: #333;
    font-weight: 500;
}

.cg-admin-login-body .cg-admin-login-box input{
    width: 100%;
    padding: 10px;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    border-radius: 10px;
    font-family: "Inter";
    color: #333;
    margin: 5px 0 10px;
}

.cg-admin-login-body .cg-admin-login-box input:focus{
    outline: none;
    color: #000;
}

.cg-admin-login-body .cg-admin-login-box .wrap-eye{
    position: relative;
}

.cg-admin-login-body .cg-admin-login-box .wrap-eye i{
    position: absolute;
    color: #5a4abd;
    top: 15px;
    right: 10px;
    cursor: pointer;
}

.cg-admin-login-body .cg-admin-login-box button{
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 10px;
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    background: linear-gradient(90deg,
      rgba(112, 92, 236, 1) 0%,
      rgba(42, 81, 186, 1) 100%);
}

.cg-admin-login-body .cg-admin-login-box button:hover{
    background: linear-gradient(-90deg,
      rgba(112, 92, 236, 1) 0%,
      rgba(42, 81, 186, 1) 100%);
}

.cg-admin-login-body .cg-admin-login-box .err-msg{
    color: #d00000;
    font-size: 12px;
}

.cg-admin-login-body .cg-admin-login-box .err-msg i{
    margin-right: 5px;
    border-radius: 50%;
    border: 3px solid #ffdddd;
}