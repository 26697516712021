.header-modern-template-format-resume-template {
  width: 100%;
  min-height: 1122.520px;
  padding: 30px;
  background-color: white;
  position: relative;
  background-size: cover;
  background-position: right;
}

.header-modern-template-format-resume-template .resume-qr-code {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.header-modern-template-format-resume-template h4 {
  font-size: 14px;
  text-align: right;
}

.header-modern-template-format-name-data {
display: flex;
flex-direction: column;
align-items: end;

}

.header-modern-template-format-section .header-modern-template-format-contact-me {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}

.header-modern-template-format-section .header-modern-template-format-name {
  width: 100%;
}

.header-modern-template-format-resume-template .header-modern-template-img-sec {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.header-modern-template-img-sec {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background-position: center;
  background-size: cover;
  
}

.header-modern-template-format-name-data h1 {
  text-align: end;
  text-transform: uppercase;
  font-size: 30px;
}

/* Contact */

.header-modern-template-format-contact-grp {
  display: flex;
  row-gap: 10px;
  column-gap: 10px;
  margin-bottom: 10px;
  font-weight: 500;
}
.header-modern-template-format-contact-grp:last-child{
  margin-bottom: 0;
}
.header-modern-template-format-contact-grp i {
  font-size: 13px;
  padding-top: 4px;
}

.header-modern-template-format-section h2 {
  color: black;
  padding-bottom: 2px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-bottom: 3px solid #d9d9d9;
  margin: 10px 0px 5px 0px ;
  font-size: 18px;
}

.header-modern-template-format-section .common-list-styling{
  display: inline-flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 30px;
}

.header-modern-template-format-section .common-list-styling li {
  margin-right: 10px;
  list-style: square;
  list-style-position: inside;
  column-gap: 10px;
  font-weight: 500;
}

.header-modern-template-format-section .common-details .common-level{
  display: flex;
  justify-content: space-between;
  gap: 2%;
}

.header-modern-template-format-section .common-details{
  padding-bottom: 5px;
}

.header-modern-template-format-section .common-details:nth-last-child(1){
  padding-bottom: 0px;
}
.header-modern-template-format-resume-template .cl-p-b{
  width: 70%;
} 
.header-modern-template-format-resume-template .cl-p-b{
  width: 28%;
  text-align: right;
}