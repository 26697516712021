.error-container {
    width: 100%;
    font-family: "Montserrat", sans-serif;
    background: #fff;
    padding: 80px 0;
}

.error-container .error-section {
    width: 50%;
    margin: auto;
    text-align: center;
}

.error-container h1 {
    font-size: 7rem;
    font-weight: 800;
}

.error-container #show-error {
    text-transform: uppercase;
    color: #E90A0A;
    font-size: 20px;
}

.error-container #content-page {
    margin-bottom: 10px;
    font-size: 20px;
}

.error-container #information-pargraph {
    font-weight: 500;
    margin-bottom: 20px;
    font-family: 'mulish';
    font-size: 15px;
}

.error-container button {
    padding: 15px;
    background: linear-gradient(90deg, rgba(112, 92, 236, 1) 0%, rgba(42, 81, 186, 1) 100%);
    text-decoration: none;
    color: white;
    border-radius: 10px;
    font-family: "inter";
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    border: none;
    margin-bottom: 20px;
    cursor: pointer;
    width: 38%;
    word-spacing: 1px;
}
.error-container button:hover{ 
     background: linear-gradient(90deg, rgba(42, 81, 186, 1) 0%, rgba(112, 92, 236, 1) 100%);
}
.error-container #information-contact {
    font-weight: 500;
    font-size: 15px;
    font-family: mulish;
}

.error-container img {
    height: 150px;
}