.NuevaTemplate {
    width: 100%;
    min-height: 1120px;
    background-color: #fff;
    padding: 30px;
    background-size: cover;
    position: relative;
    background-position: right;
}

.NuevaTemplate .resume-qr-code {
    position: absolute
}

.NuevaTemplate .fisrrt-hr hr {
    height: 3px;
    background-color: #C2D6D1;
    border: none;
    width: 100%;
}

.NuevaTemplate .intro {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 16px 0;
    text-align: center;
}

.NuevaTemplate .intro .details ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 500;
    list-style: none;
}

.NuevaTemplate h1 {
    font-size: 30px;
    color:black;
}

.NuevaTemplate .summary-sec .para {
    margin-bottom: 15px;
}

.NuevaTemplate .summary-sec .headingbox {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
}

.NuevaTemplate .summary-sec .headingbox hr {
    position: absolute;
    height: 2px;
    background-color: #C2D6D1;
    border: none;
    width: 100%;
}

.NuevaTemplate .summary-sec .headingbox .centered {
    z-index: 3;
    background-color: #C2D6D1;
    padding: 0.2rem 1rem;
    min-width: 23rem;
    text-align: center;
}

.NuevaTemplate .summary-sec .headingbox .centered h2{
    position: relative;
    z-index: 2;
}

.NuevaTemplate .common-list-styling {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.NuevaTemplate .common-list-styling .before-icon {
    width: 48%;
    display: flex;
    flex-wrap: wrap;
    gap: 4%;
    margin-bottom: 8px;
}

.NuevaTemplate .common-list-styling .before-icon:nth-last-child(1) {
    margin-bottom: 0px;
}

.NuevaTemplate .common-list-styling .before-icon svg {
    height: 1rem;
}

.NuevaTemplate .common-list-styling ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 4%;
}

.NuevaTemplate .common-list-styling ul li {
    list-style: none;
    width: 48%;
    font-weight: 500;
}

.NuevaTemplate .data-secction {
    margin-bottom: 15px;
}

.NuevaTemplate .data-secction .common-details {
    padding-bottom: 5px;
}

.NuevaTemplate .data-secction .common-details:nth-last-child(1) {
    padding-bottom: 0px;
}