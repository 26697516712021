.qr-code-popup h4 {
    font-family: "inter";
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: rgb(92, 92, 92);
    line-height: 1.3em;
    margin-bottom: 10px;
}

.qr-code-popup .copy-button {
    border: 1px solid #e5e5e5;
    padding: 10px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
}
.qr-code-popup .copy-button a {
    font-family: "inter";
    font-size: 12px;
    color: #5A4ABD;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #E5E5E5;
}

.qr-code-popup .copied-message {
    position: absolute;
    bottom: 28%;
    right: 20px;
    background-color: #5a4abd;
    color: white;
    padding: 5px;
    border-radius: 3px;
    font-size: 12px;
    transition: opacity 0.2s ease;
}

.qr-code-popup .qr-position {
    display: flex;
    column-gap: 5%;
}

.qr-code-popup .qr-position .cv-qr-poss {
    width: 40%;
    height: 120px;
    border: 3px solid #e5e5e5;
    position: relative;
}

.qr-code-popup .qr-position .cv-qr-poss .qr-pos {
    width: 30px;
    height: 30px;
    border: 3px solid #e5e5e5;
    position: absolute;
    cursor: pointer;
}

.qr-code-popup .qr-position .cv-qr-poss .qr-top-left {
    top: -3px;
    left: -3px;
}

.qr-code-popup .qr-position .cv-qr-poss .qr-top-right {
    top: -3px;
    right: -3px;
}

.qr-code-popup .qr-position .cv-qr-poss .qr-bottom-left {
    bottom: -3px;
    left: -3px;
}

.qr-code-popup .qr-position .cv-qr-poss .qr-bottom-right {
    bottom: -3px;
    right: -3px;
}

.qr-code-popup .qr-position .controls {
    width: 55%;
}

.qr-code-popup .qr-position .controls p {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 600;
    color: #333;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.qr-code-popup .qr-position .controls .range {
    display: flex;
    justify-content: space-between;
}

.qr-code-popup .qr-position .controls .range i {
    cursor: pointer;
}

.qr-code-popup .qr-position .controls .range input[type="range"] {
    width: 60%;
}