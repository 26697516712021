.simple-professional-template {
    width: 100%;
    min-height: 1120px;
    position: relative;
    display: flex;
    padding: 30px;
    text-align: left;
    gap: 5%;
    background-color: WHITE;
    background-size: cover;
    background-position: right;
}

.simple-professional-template .resume-qr-code {
    position: absolute;
}

.simple-professional-template .left-column {
    width: 38%;
}

.simple-professional-template h1 {
    font-size: 40px;
    margin-bottom: 8px;
    text-align: center;
}

.simple-professional-template h4 {
    font-size: 14px;
    margin-bottom: 30px;
    text-align: center;
}

.simple-professional-template h2 {
    margin: 10px 0px 10px 0px;
    border-bottom: 2px solid #608ABF;
    padding-bottom: 2px;
    color: black;
    /* width: 70%; */
    text-transform: uppercase;
}

.simple-professional-template .left-column .personal-details {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.simple-professional-template .left-column .personal-details .profile-img {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
}

.simple-professional-template .left-column .contact-details ul li {
    list-style: none;
    margin-bottom: 5px;
    display: flex;
    font-weight: 500;
}

.simple-professional-template ul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.simple-professional-template .left-column .contact-details i {
    margin-right: 5px;
    font-size: 14px;
    color: #608ABF;
    width: 18px;
    height: 18px;
    display: inline-flex;
    justify-content: center;
    position: relative;
    margin-top: 4px;
}

.simple-professional-template .common-list-styling ul li {
    list-style: none;
    margin-bottom: 5px;
    position: relative;
    padding-left: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;

}

.simple-professional-template .common-list-styling ul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.simple-professional-template .common-list-styling li .list-dots {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    background-color: #608ABF;
    /* bottom: 10px;
    top: 5px; */
    left: 0px;
}

.simple-professional-template .right-column {
    width: 57%;
}

.simple-professional-template .right-column .common-section .common-details .common-level {
    width: 100%;
    gap: 8%;
    display: flex;
    justify-content: space-between;
}

.simple-professional-template .right-column .common-section .common-details {
    padding-bottom: 8px;
    position: relative;
    border-left: 2px solid #608ABF;
    padding-left: 20px;
}

.simple-professional-template .right-column .common-section .common-details .all-name-section-wdt {
    width: 46%;
}

.simple-professional-template .right-column .common-section .common-details .all-name-section-date {
    width: 46%;
    text-align: end;
}

.simple-professional-template .right-column .common-section .common-details .chain-type-dots {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    left: -5px;
}

.simple-professional-template .right-column .common-section .common-details:nth-last-child(1) {
    padding-bottom: 0px;
}