.unique-style-resume {
    width: 100%;
    min-height: 1120px;
    position: relative;
    padding: 40px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(182, 159, 223, 1) 50%);
    background-size: cover;
}

.unique-style-resume .resume-qr-code {
    position: absolute;
    right: 60px;
    bottom: 60px;
}

.unique-style-resume .resume-header-section {
    display: flex;
    padding: 30px;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 30px;
}

.unique-style-resume .resume-header-section .profile-img {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
}

.unique-style-resume h1 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 10px;
    text-align: left;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 10px;
}

.unique-style-resume .personal-details {
    flex: 1;
    position: relative;
    padding-left: 20px;
}

.unique-style-resume h4 {
    font-size: 14px;
    background-color: #DF61A6;
    padding: 5px 10px;
    text-align: center;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 30px;
    color: white;
}

.unique-style-resume h2 {
    margin: 10px 0px;
    padding-bottom: 3px;
    text-align: left;
    border-bottom: 1px solid #e5e5e5;
    text-transform: uppercase;
}

.unique-style-resume .final-section {
    display: flex;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: white;
    border-radius: 30px;
    padding: 0 20px;
    margin-top: 40px;
    padding-top: 10px;
}

.unique-style-resume .final-section .left-column {
    width: 57%;
    text-align: left;
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid #e5e5e5;
}

.unique-style-resume .final-section .common-section .common-details {
    display: flex;
    padding-bottom: 15px;
    width: 100%;
    margin-top: -5px;
}

.unique-style-resume .final-section .common-section .common-details:nth-last-child(1) {
    padding-bottom: 0px;
}
.unique-style-resume .final-section .common-section .common-details div {
    width: 80%;
    padding-left: 20px;
}


.unique-style-resume .final-section .common-section .common-details .common-date-line {
    width: 66%;
    padding: 0;
    display: flex;
    flex-direction: column;
    position: relative;
}
.unique-style-resume .final-section .common-section .common-details .common-data-grp{
    width: 85%;
}

.unique-style-resume .final-section .common-section .common-details .common-date-line .common-duration {
    background-color: #DF61A6;
    border-radius: 30px;
    padding: 3px 6px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.unique-style-resume .final-section .contact-details {
    background-color: #FFD6F3;
    padding: 15px 10px;
    margin-top: 20px;
    border-radius: 20px;
}

.unique-style-resume .final-section .contact-details li {
    list-style: none;
    font-weight: 500;
    display: flex;
    margin-bottom: 10px;
}

.unique-style-resume .final-section .contact-details ul li:last-child{
    margin-bottom: 0px;
}

.unique-style-resume .final-section .contact-details ul {
    column-gap: 15px;
    row-gap: 10px;
}

.unique-style-resume .final-section .contact-details i {
    margin-right: 10px;
    font-size: 10px;
    background-color: #DF61A6;
    color: white;
    padding: 10px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.unique-style-resume .final-section .right-column {
    width: 40%;
    padding-bottom: 20px;
}

.unique-style-resume .final-section .left-column .skills-information ul {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.unique-style-resume .final-section .left-column .skills-information li {
    background-color: #DF61A6;
    padding: 5px 10px;
    border-radius: 20px;
    color: white;
    list-style: none;
    font-weight: 500;
}

.unique-style-resume .final-section .education-details {
    padding-bottom: 15px;
    margin-top: -5px;
}

.unique-style-resume .final-section .education-details:nth-last-child(1) {
    padding-bottom: 0px;
}

.unique-style-resume .final-section .project-details {
    padding-bottom: 15px;
    margin-top: -5px;
}

.unique-style-resume .final-section .project-details:nth-last-child(1) {
    padding-bottom: 0px;
}

.unique-style-resume .final-section .right-column .hobbies-information ul {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.unique-style-resume .final-section .right-column .hobbies-information li {
    background-color: #DF61A6;
    padding: 5px 10px;
    border-radius: 20px;
    color: white;
    list-style: none;
    font-weight: 500;
}

.unique-style-resume .final-section .right-column .languages-information ul {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.unique-style-resume .final-section .right-column .languages-information li {
    background-color: #DF61A6;
    padding: 5px 10px;
    border-radius: 20px;
    color: white;
    list-style: none;
    font-weight: 500;
}
