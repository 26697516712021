.blog-post-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center; 
    align-items: center; 
    z-index: 1000; 
  }
  .blog-post-popup-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 900px;
    width: 100%; 
    position: relative;
    height: 600px;
    overflow-y: auto;
  }
  .blog-post-popup-container::-webkit-scrollbar{
    display: none;
  }
  .blog-post-popup-container .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
  }

  .blog-post-popup-overlay .cancel-btn {
    width: 22px;
    height: 22px;
    color: #000;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.blog-post-popup-overlay .cancel-btn:hover {
    color: #5a4abd;
    animation: AnimOnCancelBtn 0.2s linear forwards alternate;
}
.blog-post-popup-container .bp-text{
    margin-bottom: 15px;
}
.blog-post-popup-overlay form h3 {
    font-family: "Inter";
    font-size: 12px;
    text-transform: uppercase;
    color: #333;
    font-weight: 500;
}
.blog-post-popup-container .bp-text input[type='text']{
    width: 100%;
    padding: 10px;
    border: 1px solid #e5e5e5;
    background-color: #fafafa;
    border-radius: 10px;
    font-family: "Inter";
    color: #333;
    margin: 5px 0 10px;
    outline: none;
}

.blog-post-popup-container h2{
    font-family: "inter";
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;

}
.blog-post-popup-container #inp-addSpacing{
  margin-top: 15px;
}

.blog-post-popup-container .bp-radio-input-grp {
  display: flex;
  gap: 40px; 
  align-items: center; 
  margin-top: 12px;
  /* padding-left: 10px; */
}


.blog-post-popup-container .bp-radio-input-grp input[type="radio"] {
  margin: 0;
  padding: 0;
}


.blog-post-popup-container .bp-radio-input-grp label {
  margin-left: 8px; 
  font-size: 14px; 
  cursor: pointer; 
  font-family: 'inter';
}

.blog-post-popup-container .bp-radio-input-grp .radio-inp-sep{
  display: flex;
  justify-content: flex-start;
}

.blog-post-popup-container .schedule-grp{
display: flex;
    top: bottom;
    bottom: 28px;
    align-items: center;
    right: 20px;
    gap: 15px;
    margin-bottom: 15px;
}
.blog-post-popup-container .schedule-grp label{
  font-size: 14px;
}

.blog-post-popup-container .schedule-grp .sch-grp-a,.blog-post-popup-container .schedule-grp .sch-grp-b{
  display: flex;
  align-items: center;
gap: 10px;
}
.blog-post-popup-container .schedule-grp input#schedule-date ,.blog-post-popup-container .schedule-grp input#schedule-time{
  border: 1px solid #e5e5e5;
  padding: 4px;
  font-family: 'Inter';
  outline: none;
  border-radius: 6px;
}
.blog-post-popup-container .scheduled-label{
  display: flex;
  gap: 12px;
  align-items: center;
  font-family: inter;
}
.blog-post-popup-container .scheduled-label p{
  font-size: 14px;
}
.blog-post-popup-container .image-preview{
  margin-top: 10px;
}
.blog-post-popup-container .blog-image-grp{
  margin-top: 10px;
  display: flex;
  gap: 20px;
  position: relative;
}
.blog-post-popup-container .blog-image-grp .blog-image-preview{
  width: 48%;
}

.blog-post-popup-container .blog-image-grp  input[type="file"] {
 display: none;
}
.blog-post-popup-container .blog-image-preview{
  height: 200px;
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
  border-radius: 10px;
}

.blog-post-popup-container .blog-image-grp label{
  padding: 10px 25px;
  font-family: "inter";
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid rgba(37, 32, 70, 0.1);
  color: #000;
  font-weight: 500;
  transition: all 0.3s ease;
  position: absolute;
  background-color: #F0F0F0;
  bottom: 0;
  left: 50%;
  cursor: pointer;
}
.blog-post-popup-container .blog-image-grp label:hover{
  background-color: rgba(37, 32, 70, 0.15);
}
.blog-post-popup-container .blog-image-grp .remove-btn {
  padding: 10px 25px;
  font-family: "inter";
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid rgba(37, 32, 70, 0.1);
  color: #fff;
  font-weight: 500;
  transition: all 0.3s ease;
  position: absolute;
  background-color: #ff4d4d;
  bottom: 0;
  left: 68%;
  cursor: pointer;
  border: none;
}

.blog-post-popup-container .blog-image-grp .remove-btn:hover {
  background-color: #e60000;
}

.blog-post-popup-container .bp-text input::placeholder{
  font-family: 'inter';
  font-style: italic;
}

.blog-post-popup-container .save-blog-btn-container{
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}
.blog-post-popup-container .save-blog-btn-container button{
  background-color: #4CAF50 ;
  border: none;
  color: #fff;
}
.blog-post-popup-container .save-blog-btn-container button:hover{
  background-color: #449C47;
}
.blog-post-popup-container .cg-create-bp{
  margin-bottom: 15px;
}
.blog-post-popup-container .save-blog-btn-container button#btn-publish{
  background-color: #7D73BF;
}
.blog-post-popup-container .save-blog-btn-container button#btn-publish:hover{
  background-color: #7065B8;
}