.blue-creative-modern-resume {
    width: 100%;
    min-height: 1120px;
    position: relative;
    gap: 5%;
    background-size: cover;
    background-color: #ffffff;
    background-position: right;
}

.blue-creative-modern-resume .resume-qr-code {
    position: absolute;
}

.blue-creative-modern-resume .header {
    display: flex;
    column-gap: 50px;
    padding: 20px 0px;
    justify-content: center;
    align-items: center;
    color: white;
}

.blue-creative-modern-resume .header .profile-img {
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border: 8px solid #fff;
    margin-left: 70px;
}

.blue-creative-modern-resume .personal-details {
    width: 50%;
}

.blue-creative-modern-resume .header .personal-details h1 {
    font-size: 35px;
    margin-bottom: 10px;
}

.blue-creative-modern-resume .header .personal-details h4 {
    font-size: 14px;
}

.blue-creative-modern-resume h2 {
    margin: 10px 0px;
    text-transform: uppercase;
}

.blue-creative-modern-resume .main-div {
    display: flex;
    text-align: left;
    width: 100%;
    padding-bottom: 10px;
}

.blue-creative-modern-resume .main-div .left-column {
    width: 40%;
    padding: 10px 0px 50px 30px;
}

.blue-creative-modern-resume .main-div .right-column {
    width: 60%;
    padding: 10px 30px 50px 30px;
}

.blue-creative-modern-resume .contact-details ul li {
    margin-bottom: 8px;
    list-style: none;
    color: #000000;
    display: flex;
    font-weight: 500;
}
.blue-creative-modern-resume .contact-details ul li:last-child{
    margin-bottom: 0;
}
.blue-creative-modern-resume .main-div .left-column .contact-details ul a:nth-last-child(1) li {
    margin-bottom: 0px;
}

.blue-creative-modern-resume .main-div .left-column .contact-details i {
    margin-right: 15px;
    font-size: 12px;
    background-color: #03989f;
    border-radius: 50%;
    color: #ffffff;
    padding: 12px;
    width: 15px;
    height: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.blue-creative-modern-resume .main-div .left-column .common-list-styling ul li {
    margin-bottom: 3px;
    list-style: none;
    color: #000000;
    position: relative;
    padding-left: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.blue-creative-modern-resume .main-div .left-column .common-list-styling ul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.blue-creative-modern-resume .main-div .left-column .common-list-styling li .list-dots {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    background-color: #03989f;
    /* top: 5.4px; */
    left: 0px;
}

.blue-creative-modern-resume .common-section .common-details i {
    font-weight: 500;
}

.blue-creative-modern-resume .common-section .common-details {
    padding-bottom: 8px;
}

.blue-creative-modern-resume .common-section .common-details:nth-last-child(1) {
    padding-bottom: 0px;
}

.blue-creative-modern-resume .bottom-color-1 {
    width: 60%;
    height: 40px;
    background-color: #90b9ab;
    border-top-left-radius: 20px;
    position: absolute;
    left: 0;
    bottom: 0;
}

.blue-creative-modern-resume .bottom-color-2 {
    width: 50%;
    height: 40px;
    background-color: #03989f;
    border-top-left-radius: 20px;
    right: 0%;
    position: absolute;
    bottom: 0;
}
.blue-creative-modern-resume .left-column .bcmrt h2{
    margin-top: 12px; 
    margin-bottom: 6px;
}
.blue-creative-modern-resume  .bcmrt-b h2{
    margin-bottom: 5px; 
}