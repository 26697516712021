.cg-admin-dashboard-header{
    position: sticky;
    top: 0;
    padding: 5px 20px;
    background-color: #252046;
    display: flex;
    justify-content: space-between;
    z-index: 100;
    height: 45px;
    align-items: center;
}
.cg-admin-dashboard-header img.cg-career-logo-icon{
    width: 150px;
}
 .cg-admin-dashboard-header  .details img {
    width: 32px;
    border-radius: 50px;
 }
 .cg-admin-dashboard-header  .details{
 display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: #fff;
    font-family: 'inter';
    cursor: pointer;
 }
 .cg-bg-dashboard{
    display: flex;
    width: 100vw;
 }
 .cg-bg-dashboard .admin-features-options{
    width: 210px;
 }
  .cg-bg-dashboard .admin-features-page{
    width: 88%;
    padding: 20px;
 }