.sidebar {
  position: relative;
  background-color: #fafafa;
  width: 12%;
  position: relative;
  border-right: 1px solid #e5e5e5;
  padding-top: 20px;
}

.sidebar .sidebar-icon {
  border-bottom: 1px solid #e5e5e5;
  padding: 15px;
  cursor: pointer;
  font-family: "Mulish";
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: clamp(8px, 1vw, 14px);
  position: relative;
}

.sidebar .sidebar-icon:hover {
  background-color: #f2f2f2;
  color: #5a4abd;
}

.sidebar .sidebar-icon svg {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

.sidebar .sidebar-icon .premium {
  position: absolute;
  width: 12px;
  height: 12px;
  right: -5px;
  color: #DAB04A;
}

.sidebar .signup-error {
  font-size: 10px;
  font-weight: 700;
  font-family: "Mulish";
  color: #FC5656;
  padding: 10px 15px;
  cursor: pointer;
  position: relative;
}

.sidebar .signup-error:hover {
  color: #d00000;
}

.sidebar .signup-error:hover::before{
  background-color: #d00000;
}

.sidebar .signup-error::before {
  content: '';
  width: 3px;
  border-radius: 0 20px 20px 0;
  height: 100%;
  background-color: #FC5656;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s;
}


/* RESPONSIVE CSS */
@media screen and (max-width: 1440px) {
  .sidebar {
    width: 15%;
  }

  .sidebar .sidebar-icon .premium {
    width: 10px;
    height: 10px;
    right: -5px;
  }
}

@media screen and (max-width: 1024px) {
  .sidebar .signup-error {
    display: none;
  }

  .sidebar .sidebar-icon p {
    display: none;
  }

  .sidebar .sidebar-icon .premium {
    min-width: 12px;
    min-height: 12px;
    top: 5px;
    right: 10px;
  }

  .sidebar {
    width: auto;
    padding-top: 0;
  }

  .sidebar .sidebar-icon svg {
    min-width: 20px;
    min-height: 20px;
    margin-right: 0px;
  }

  .sidebar .sidebar-icon {
    padding: 20px;
  }
}

@media screen and (max-width: 768px) {
  .Full-page .sidebar .sidebar-icon .premium {
    width: 15px;
    height: 15px;
    top: 10px;
    left: -12px;
    color: #DAB04A !important;
  }
}

@media screen and (max-width: 450px) {
  .Full-page .sidebar .sidebar-icon .premium {
    width: 14px;
    height: 14px;
  }
}