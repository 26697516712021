.after-border-resume-template {
    width: 100%;
    min-height: 1120px;
    position: relative;
    padding: 0px 30px 30px 30px;
    text-align: left;
    background-color: white;
    background-size: cover;
    background-position: right;
}

.after-border-resume-template .resume-qr-code {
    position: absolute;
}

.after-border-resume-template .personal-details {
    border-top: 35px solid #e5e5e5;
    padding: 15px 0px 8px 0px;
    border-bottom: 2px solid #b0b0b0;
}

.after-border-resume-template h1 z {
    font-size: 30px;
}

.after-border-resume-template h4 {
    font-size: 14px;
}

.after-border-resume-template p {
    line-height: 18px;
}

.after-border-resume-template h2 {
    font-size: 14px;
    margin: 10px 0px 10px 0px;
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: #2D5B7A;
    text-transform: uppercase;
}

.after-border-resume-template h2 .theme-design {
    width: 70%;
    height: 18px;
    background-color: #e5e5e5;
}

.after-border-resume-template b {
    font-size: 12px;
}

.after-border-resume-template .summary-information {
    border-bottom: 2px solid #b0b0b0;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.after-border-resume-template .main {
    display: flex;
    gap: 5%;
}

.after-border-resume-template .left-column {
    width: 57%;
}

.after-border-resume-template .common-sec {
    border-bottom: 2px solid #b0b0b0;
    margin-bottom: 10px;
    padding-bottom: 8px;
}

.after-border-resume-template .common-details {
    padding-bottom: 10px;
}

.after-border-resume-template .common-details:nth-last-child(1) {
    padding-bottom: 0px;
}

.after-border-resume-template .right-column {
    width: 38%;
}

.after-border-resume-template .right-column .contact-details ul li {
    list-style: none;
    margin-bottom: 10px;
}

.after-border-resume-template .right-column .contact-details ul a:nth-last-child(1) li {
    margin-bottom: 0px;
}

.after-border-resume-template .right-column .contact-details i {
    margin-right: 10px;
    color: rgb(0, 0, 0);
    display: inline-flex;
    justify-content: center;
    margin-top: 4px;
}

.after-border-resume-template .contact-details li {
    display: flex;
}

.after-border-resume-template .contact-details {
    border-bottom: 2px solid #b0b0b0;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.after-border-resume-template .skills-information ul li {
    list-style: square;
    list-style: inside;
    margin-bottom: 10px;
}

.after-border-resume-template .right-column .skills-information ul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.after-border-resume-template .skills-information {
    border-bottom: 2px solid #b0b0b0;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.after-border-resume-template .hobbies-information ul li {
    list-style: square;
    list-style: inside;
    margin-bottom: 10px;
}

.after-border-resume-template .right-column .hobbies-information ul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.after-border-resume-template .hobbies-information {
    border-bottom: 2px solid #b0b0b0;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.after-border-resume-template .languages-information ul li {

    list-style: square;
    list-style: inside;
    margin-bottom: 10px;
 
}

.after-border-resume-template .right-column .languages-information ul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.after-border-resume-template .languages-information {
    border-bottom: 2px solid #b0b0b0;
    margin-bottom: 10px;
    padding-bottom: 10px;
}