body,
body * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.overflow-hidden {
  overflow: hidden;
}

body {
  overflow-x: hidden;
}

body a {
  text-decoration: none;
}

button {
  cursor: pointer;
  z-index: 10;
}

ul {

}

li {
  list-style-position: inside;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #e5e5e5;
}

body::-webkit-scrollbar-thumb {
  background-color: #CDCDCD;
}

body *::-webkit-scrollbar {
  width: 10px;
  background-color: #e5e5e5;
}

body *::-webkit-scrollbar-thumb {
  background-color: #CDCDCD;
}

/* *** ANIMATIONS *** */

@keyframes TopSliding {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes Fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes LeftSliding {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes RightSliding {
  from {
    opacity: 0;
    transform: translateX(100px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes ScaleEffect {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes RotateCircle {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-180deg);
  }
}

@keyframes AnimOnCancelBtn {
  100% {
    transform: rotate(90deg);
  }
}

/* Heading H1 */
.career-gennie-web-h1 {
  margin-bottom: 15px;
  font-family: "montserrat", sans-serif;
  font-size: 50px;
  line-height: 1.1em;
  font-weight: 450;
  color: #000;
}

/* Choose Tempalte page */
.career-gennie-web-h1-ctp {
  text-align: center;
}

.career-gennie-web-h1 span {
  background: rgba(112, 92, 236, 1);
  background: linear-gradient(to right, #5a4abd 40%, rgb(6, 88, 230) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .career-gennie-web-h1 {
    font-size: 35px;
    text-align: center;
  }
}

@media screen and (max-width: 450px) {
  .career-gennie-web-h1 {
    font-size: 30px;
  }
}

/* Heading H1 */

/* Heading H2 */
.career-gennie-web-h2 {
  font-size: 36px;
  font-family: "Montserrat";
  margin-bottom: 10px;
}

.career-gennie-web-home-h2 {
  font-size: 36px;
  font-family: "Montserrat";
  margin-bottom: 15px;
  text-align: center;
  font-weight: 450;
}

.career-gennie-web-home-h2 span {
  background: rgba(112, 92, 236, 1);
  background: linear-gradient(to right, #5a4abd 40%, rgb(6, 88, 230) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

@media screen and (max-width: 450px) {
  .career-gennie-web-home-h2 {
    font-size: 26px;
  }
}

.career-gennie-web-home-h2-wh {
  font-size: 36px;
  font-family: "Montserrat";
  margin-bottom: 15px;
  text-align: start;
  font-weight: 450;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .career-gennie-web-home-h2-wh {
    text-align: center;
  }
}

.career-gennie-web-home-h2-wh span {
  font-weight: 600;
  color: #fff;
}

@media screen and (max-width: 450px) {
  .career-gennie-web-home-h2-wh {
    text-align: center;
    font-size: 26px;
  }
}

/* Heading H2 */

/* Para */
.career-gennie-web-para {
  font-family: "Mulish";
  color: #333;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.4em;
  margin-bottom: 15px;
}

/* Para */

/* Button */
.career-gennie-web-button {
  padding: 12px 60px;
  font-size: 18px;
  font-weight: 300;
  font-family: "Inter";
  border: 2px solid #5a4abd;
  border-radius: 10px;
  border: none;
  background: linear-gradient(90deg,
      rgba(112, 92, 236, 1) 0%,
      rgba(42, 81, 186, 1) 100%);
  color: #fff;
}

.career-gennie-web-button:hover {
  background: linear-gradient(90deg,
      rgba(42, 81, 186, 1) 0%,
      rgba(112, 92, 236, 1) 100%);
}

@media screen and (max-width: 450px) {
  .career-gennie-web-button {
    margin-top: 0px;
  }
}

/* Button */