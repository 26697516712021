.big-image-typography-template {
    width: 100%;
    min-height: 1120px;
    position: relative;
    display: flex;
    gap: 10%;
    background-color: white;
    background-size: cover;
    background-position: right;
}

/* globel Css */

.big-image-typography-template .left-column {
    width: 40%;
    padding: 80px 20px 0px 30px;
}

.big-image-typography-template .resume-qr-code {
    position: absolute;
}

.big-image-typography-template h1 {
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 10px;
    text-align: left;
}

.big-image-typography-template h4 {  
    text-align: left;
}

.big-image-typography-template h2 {
    text-align: left;
    margin: 12px 0px 10px 0px;
    background-color: #000000;
    color: white;
    padding: 3px 20px;
    border-radius: 4px;
}

.big-image-typography-template b {
    font-size: 12px;
}

/* Contact Details  */


.big-image-typography-template .left-column .contact-details ul li {
    list-style: none;
    margin-bottom: 6px;
    /* text-align: left; */
    color: black;
    display: flex;

}

.big-image-typography-template .left-column .contact-details li p{
    display: flex;
    align-items: center;
    font-weight: 500;
}

.big-image-typography-template .left-column .contact-details ul a:nth-last-child(1) li {
    margin-bottom: 0px;
}
.big-image-typography-template .left-column .contact-details ul{
  padding-top: 2px;
}

.big-image-typography-template .left-column .contact-details i {
    margin-right: 10px;
    font-size: 12px;
    background-color: #000000;
    color: white;
    padding: 12px;
    border-radius: 4px;
    width: 15px;
    height: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

/* Skills Information css */

.big-image-typography-template .left-column .skills-information ul li {
    list-style: none;
    margin-bottom: 6px;
    text-align: left;
    position: relative;
    padding-left: 20px;
    font-weight: 500;
}

.big-image-typography-template .left-column .skills-information ul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.big-image-typography-template .left-column .skills-information li .list-dots-boxes {
    content: "";
    width: 8px;
    height:8px;
    position: absolute;
    top: 5px;
    left: 0px;
    border-radius: 2px;
}

/* Education Section Css */


.big-image-typography-template .left-column .education-information {
    text-align: left;
}

.big-image-typography-template .left-column .education-details {
    padding-bottom: 8px;
}

.big-image-typography-template .left-column .education-details:nth-last-child(1) {
    padding-bottom: 0px;
}

.big-image-typography-template .left-column .hobbies-information ul li {
    list-style: none;
    margin-bottom: 6px;
    text-align: left;
    position: relative;
    padding-left: 20px;
    font-weight: 500;
}

.big-image-typography-template .left-column .hobbies-information ul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.big-image-typography-template .left-column .hobbies-information li .list-dots-boxes {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    top: 5px;
    left: 0px;
    border-radius: 2px;
}

/* Right Column And Image Css */


.big-image-typography-template .right-column {
    width: 50%;
}

.big-image-typography-template .right-column .profile-img {
    width: 100%;
    height: 300px;
    background-position: center;
    background-size: cover;
}

/* Summary section Css */

.big-image-typography-template .right-column .summary-information {
    text-align: left;
    background-color: #000000;
    padding: 20px 30px 20px 30px;
    border-bottom-left-radius: 4px;
    margin-bottom: 14px;
}

.big-image-typography-template .right-column .summary-information h2 {
    background-color: white;
    color: black;
    width: 70%;
    margin-top: 0px;
}

/* Project Details Css */

.big-image-typography-template .right-column .project_information h2 {
    width: 70%;
}

.big-image-typography-template .right-column .project_information .project_details {
    text-align: left;
    padding-right: 30px;
    padding-bottom: 8px;
}

.big-image-typography-template .right-column .project_information .project_details:nth-last-child(1) {
    padding-bottom: 0px;
}

/* Experience Details Css */


.big-image-typography-template .right-column .experience-information h2 {
    width: 70%;
}

.big-image-typography-template .right-column .experience-details {
    padding-bottom: 8px;
    padding-right: 30px;
}

.big-image-typography-template .right-column .experience-details:nth-last-child(1) {
    padding-bottom: 0px;
}

.big-image-typography-template .right-column .certification-information h2 {
    width: 70%;
}

.big-image-typography-template .right-column .certification-details {
    padding-bottom: 8px;
    padding-right: 30px;
}

.big-image-typography-template .right-column .certification-details:nth-last-child(1) {
    padding-bottom: 0px;
}

.big-image-typography-template .right-column .languages-information h2 {
    width: 70%;
}

.big-image-typography-template .right-column .languages-information ul li {
    list-style: none;
    margin-bottom: 6px;
    text-align: left;
    position: relative;
    padding-left: 20px;
    font-weight: 500;
   
}


.big-image-typography-template .right-column .languages-information ul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.big-image-typography-template .right-column .languages-information li .list-dots-boxes {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    top: 5px;
    left: 0px;
    border-radius: 2px;
}