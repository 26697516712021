.professional-web-developer-resume {
    width: 100%;
    min-height: 1120px;
    position: relative;
    padding: 30px;
    background-color: white;
    background-size: cover;
    background-position: right;
}

.professional-web-developer-resume .resume-qr-code {
    position: absolute;
}

.professional-web-developer-resume .header {
    display: flex;
    align-items: center;
}

.professional-web-developer-resume .profile-img {
    width: 200px;
    height: 170px;
    background-position: center;
    background-size: cover;
    border: 4px solid #574874;
    border-radius: 30px;
}

.professional-web-developer-resume .header .sub-left-column {
    width: 72%;
}

.professional-web-developer-resume .header .sub-right-column {
    width: 28%;
}

.professional-web-developer-resume .header .sub-left-column .personal-details {
    background-color: #d9d9d9;
    padding: 20px;
    z-index: -1;
    font-family: "Roboto Slab", serif;
}

.professional-web-developer-resume h1 {
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 3px;
    text-align: left;
}

.professional-web-developer-resume h4 {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 3px;
    text-align: left;
}

.professional-web-developer-resume h2 {
    text-align: left;
    margin: 10px 0px;
    text-transform: uppercase;
}

.professional-web-developer-resume p {
    text-align: left;
}

.professional-web-developer-resume b {
    font-size: 12px;
}

.professional-web-developer-resume .contact-information {
    border-bottom: 2px solid #d9d9d9;
    padding-bottom: 10px;
}

.professional-web-developer-resume .main-section {
    display: flex;
}

.professional-web-developer-resume .main-section .left-column {
    width: 40%;
    padding-right: 20px;
}

.professional-web-developer-resume .contact-information ul li {
    margin-bottom: 5px;
    display: flex;
    list-style: none;
    font-weight: 500;
}

.professional-web-developer-resume .contact-information ul a:nth-last-child(1) li {
    margin-bottom: 0px;
}

.professional-web-developer-resume .contact-information i {
    margin-top: 4px;
    margin-right: 10px;
}

.professional-web-developer-resume .skills-information {
    border-bottom: 2px solid #d9d9d9;
    padding-bottom: 10px;
}

.professional-web-developer-resume .skills-information ul {
    list-style-type: disc;
}

.professional-web-developer-resume .skills-information ul li {
    margin-bottom: 5px;
    text-align: left;
    font-weight: 500;
    font-family: "Inter", sans-serif;
}

.professional-web-developer-resume .skills-information ul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.professional-web-developer-resume .hobbies-information {
    border-bottom: 2px solid #d9d9d9;
    padding-bottom: 10px;
}

.professional-web-developer-resume .hobbies-information ul li {
    list-style: disc;
    list-style: inside;
    margin-bottom: 5px;
    text-align: left;
    font-weight: 500;
    font-family: "Inter", sans-serif;
}

.professional-web-developer-resume .hobbies-information ul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.professional-web-developer-resume .languages-information {
    border-bottom: 2px solid #d9d9d9;
    padding-bottom: 10px;
}

.professional-web-developer-resume .languages-information ul li {
    list-style: disc;
    list-style: inside;
    margin-bottom: 5px;
    text-align: left;
    font-weight: 500;
    font-family: "Inter", sans-serif;
}

.professional-web-developer-resume .languages-information ul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.professional-web-developer-resume .main-section .right-column {
    width: 60%;
    border-left: 2px solid #d9d9d9;
    padding-left: 20px;
}

.professional-web-developer-resume .summary-information {
    border-bottom: 2px solid #d9d9d9;
    padding-bottom: 10px;
}

.professional-web-developer-resume .main-section .common-section {
    border-bottom: 2px solid #d9d9d9;
    padding-bottom: 10px;
}

.professional-web-developer-resume .main-section .common-section .common-details {
    padding-bottom: 5px;
}

.professional-web-developer-resume .main-section .common-section .common-details:nth-last-child(1) {
    padding-bottom: 0px;
}