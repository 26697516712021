.Yellow-Theme-Template {
    width: 100%;
    min-height: 1120px;
    position: relative;
    background-size: cover;
    background-color: #fff;
    padding-bottom: 40px;
    background-position: right;
}

.Yellow-Theme-Template .resume-qr-code {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

/* header Css */

.Yellow-Theme-Template .Header {
    display: flex;
}

.Yellow-Theme-Template .Header .Personal-Details {
    width: 60%;
    font-size: 2.6rem;
    padding: 40px 30px;
    line-height: 80px;
}

/* All hedings global Css */

.Yellow-Theme-Template h4 {
    font-size: 30px;
    padding: 80px 50px 0px 50px;
}

.Yellow-Theme-Template h2 {
    display: flex;
    align-items: center;
    margin: 10px 0px 10px 0px;
    column-gap: 10px;
    text-transform: uppercase;
}

.Yellow-Theme-Template h2 .designing-line {
    height: 5px;
    width: 100px;
}

.Yellow-Theme-Template b {
    text-transform: uppercase;
}

.Yellow-Theme-Template .Main {
    width: 100%;
    display: flex;
    gap: 5%;
}

.Yellow-Theme-Template .Left-Column {
    width: 38%;
    padding-left: 30px;
    margin-top: 15px;
}

/* Skills-Information Css */

.Yellow-Theme-Template .common-list-styling ul {
    display: flex;
    flex-wrap: wrap;
}

.Yellow-Theme-Template .common-list-styling li {
    list-style: none;
    margin: 0px 8px 8px 0px;
    font-weight: 500;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/* Right-column Css  */

.Yellow-Theme-Template .Right-Column {
    width: 57%;
    padding-right: 40px;
}

/* Contact-Information Css */

.Yellow-Theme-Template .Contact-Information ul li {
    list-style: none;
    margin-bottom: 8px;
    font-weight: 500;
    display: flex;
}

.Yellow-Theme-Template .Right-Column .Contact-Information {
    border: 2px solid #000;
}

.Yellow-Theme-Template .Right-Column .Contact-Information ul a:nth-last-child(1) li {
    margin-bottom: 0px;
}

.Yellow-Theme-Template .Right-Column .Contact-Information {
    padding: 60px 20px 20px 20px;
    margin: -40px 0px 15px 0px;
    width: 98%;
}

.Yellow-Theme-Template .Right-Column .Contact-Information i {
    margin-right: 10px;
    font-size: 10px;
    border-radius: 50%;
    min-width: 20px;
    min-height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
}


/* Common Section  Css */

.Yellow-Theme-Template .common-section .common-Details {
    display: flex;
    gap: 10px;
    padding-bottom: 6px;
}

.Yellow-Theme-Template .common-section .common-Details div {
    width: 80%;
}

.Yellow-Theme-Template .common-section .common-Details .cmn-date{
    width: 20%;
}

.Yellow-Theme-Template .common-section .common-Details:nth-last-child(1) {
    padding-bottom: 0px;
}