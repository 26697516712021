.bp-container {
  padding: 80px 0;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
}

.bp-container  .blog-post-page {
  max-width: 1400px;
  font-family: Arial, sans-serif;
  width: 80%;
}

.bp-container  .blog-post-header {
  margin-bottom: 20px;
}

.bp-container  .blog-post-header h1 {
  color: #333;
  line-height: 1.1em;
  padding-bottom: 20px;
  font-family: "montserrat", sans-serif;
  font-size: 45px;
  font-weight: 500;
}

.bp-container  .blog-post-header span p {
  font-size: 1em;
  color: #666;
  margin: 5px 0;
}
.bp-container .post-image-container img {
  border-radius: 8px;
}
.bp-container  .post-image {
  max-width: 1400px;
  height: 100%;

  width: 80%;
  object-fit: cover;
}

.bp-container  .post-content h2 {
  color: #000;
 padding: 20px 0;
 line-height: 1.2em;
  font-size: 32px;
  font-family: "Montserrat";
  font-weight: 500 !important;

}
.bp-container h3, .bp-container h4{
  font-size: 22px;
  color: #000;
  padding-bottom: 10px;
  line-height: 1em;
  font-weight: 500;
}
.bp-container h4{
  font-size: 18px;
}

.bp-container .post-content .post-content-left .details .db{
font-size: 14px;
font-style: italic;
}
.bp-container .post-content .post-content-left .details .da{
  font-weight: 700;
}

.bp-container  .post-content p {
  font-size: 16px;
  line-height: 1.6em;
  color: #000;
  font-family: "Mulish";
}

.bp-container  .blog-post-footer {
  text-align: center;
  margin-top: 30px;
}

.bp-container  .blog-post-footer p {
  font-size: 1em;
  color: #333;
}

 .bp-container .post-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.bp-container ::-webkit-scrollbar{
  display: none;
}
.bp-container  .post-content .post-content-left {
  width: 30%;
  padding: 20px 0px 20px 0;
  color: white;
  position: sticky;
  top: 80px; 
  height: 100vh;
  overflow-y: auto;
}

.bp-container  .post-content .post-content-right {
  width: 70%;
  padding: 20px 20px 20px 0;
  overflow-y: auto;
}


@media screen and (max-width:768px) {
  
  .bp-container{
    padding: 60px 0;
  }
  .bp-container .post-content{
    display: block;
  }
  .bp-container  .post-content .post-content-right  {
    width: 100%;
    padding: 20px 0 0 0;
  }
  .blog-page-vertical .blog-main{
    display: flex;
  }
  .bp-container .post-content .post-content-left{
    width: 100%;
    height: auto;
    padding-bottom: 0;
  }
  .bp-container .post-image{
    width: 100%;
  }
  .bp-container .blog-post-header{
    margin-bottom: 0px;
  }
}

@media screen and (max-width:450px) {
 
  .bp-container .post-content .post-content-left{
    height: auto;
  }
  .bp-container .post-content .post-content-right{
    padding: 20px 0 0 0;
  }
  .bp-container{
    margin-bottom: 60px 0;
  }
  .bp-container  .blog-post-header h1{
    font-size: 30px;
  }
  .bp-container  .post-content h2{
    font-size: 26px;
  }
}
