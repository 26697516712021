/* *** HEADER CSS *** */
.elegant-resume-template {
    width: 100%;
    min-height: 29.7cm;
    background-color: #fff;
    padding: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: right;
}

.elegant-resume-template .resume-qr-code {
    position: absolute;
}

.elegant-resume-template .uppercase {
    text-transform: uppercase;
}

.elegant-resume-template h1,
.elegant-resume-template h2 {
    text-transform: uppercase;
}

.elegant-resume-template h1 {
    font-weight: 600;
    letter-spacing: 5px;
    font-size: 30px;
    margin-bottom: 10px;
}

.elegant-resume-template h2 {
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: #333;
    margin: 10px 0px;
    font-weight: 600;
}

.elegant-resume-template h2 i {
    font-size: 14px;
    background-color: #f3f1f1;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.elegant-resume-template h4 {
    font-size: 14px;
}

.elegant-resume-template .job-role {
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    padding: 12px 0px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
}

.elegant-resume-template .left-right-body {
    width: 100%;
    display: flex;
    column-gap: 5%;
    padding-top: 20px;
}

/* *** LEFT SECTION CSS *** */

.elegant-resume-template .left-right-body .left-section {
    width: 35%;
}

/* CONTACT SECTION CSS */

.elegant-resume-template .left-right-body .contact {
    padding-left: 10px;
}

.elegant-resume-template .left-right-body .contact .elegant-contact {
    display: flex;
    column-gap: 10px;
    flex-wrap: wrap;
}

.elegant-resume-template li {
    display: list-item;
}

.elegant-resume-template .left-right-body .contact li {
    display: flex;
    column-gap: 10px;
    margin-bottom: 8px;
    font-weight: 600;
}

.elegant-resume-template .left-right-body .contact i {
    font-size: 13px;
    margin-top: 4px;
}

.elegant-resume-template .left-right-body .contact li:nth-last-child(1) {
    margin-bottom: 0px;
}

.elegant-resume-template .left-right-body .contact a:nth-last-child(1) li {
    margin-bottom: 0px;
}

/* COMMON LIST STYLING SECTION CSS */

.elegant-resume-template .common-list-styling {
    padding-left: 10px;
}

.elegant-resume-template .common-list-styling li {
    display: list-item;
    list-style-type: square;
    margin-bottom: 5px;
    font-weight: 600;
}

.elegant-resume-template .common-list-styling li:nth-last-child(1) {
    margin-bottom: 0px;
}


/* *** RIGHT SECTION CSS *** */

.elegant-resume-template .left-right-body .right-section {
    width: 60%;
    padding-bottom: 20px;
}

/* COMMON SECTION CSS */

.elegant-resume-template .common-sec {
    display: flex;
    column-gap: 5%;
    margin-bottom: 5px;
}

.elegant-resume-template .common-sec:nth-last-child(1) {
    margin-bottom: 0px;
}