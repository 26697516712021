/* ******** BANNER ******** */
.home-banner {
  width: 100vw;
  padding-top: 80px;
  display: flex;
  justify-content: center;
  position: relative;
}

.home-banner .row {
  max-width: 1400px;
  width: 80%;
  display: flex;
  column-gap: 45px;
  z-index: 10;
}

.home-banner .row .column {
  width: 50%;
}

.home-banner .row .column .companies {
  align-items: center;
  column-gap: 20px;
  display: flex;
  margin: 10px 0 20px;
  width: 100%;
}

.home-banner .row .column .companies img {
  width: 70px;
}

.home-banner .row .column .companies img:nth-last-child(1) {
  position: relative;
  top: 5px
}


.home-banner .row .column h4 {
  font-family: "Inter";
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  color: #333;
}

.home-banner .row .column1 .check-score-btn {
  font-weight: 400;
  color: #5a4abd;
  background-color: transparent;
}

.home-banner .row .column2 {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position-y: center;
}


.background-gradient {
  position: absolute;
  left: -15%;
  top: 30%;
  overflow: hidden;
}

.background-gradient-2 {
  position: absolute;
  right: -5%;
  top: 5%;
  overflow: hidden;
}

.home-banner .slide {
  width: 400px;
  position: absolute;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
  filter: drop-shadow(2px -2px 10px rgba(112, 92, 236, 0.2));
}

.home-banner .column .slide:nth-child(1) {
  opacity: 0;
  animation: flip 9s infinite;
  animation-delay: 0s;
}

.home-banner .column .slide:nth-child(2) {
  opacity: 0;
  animation: flip 9s infinite;
  animation-delay: 3s;
}

.home-banner .column .slide:nth-child(3) {
  opacity: 0;
  animation: flip 9s infinite;
  animation-delay: 6s;
}

@keyframes flip {
  0% {
    opacity: 0;
    transform: rotateY(90deg);
  }

  10% {
    opacity: 1;
    transform: rotateY(0deg);
  }

  30% {
    opacity: 1;
    transform: rotateY(0deg);
  }

  40% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }
}

.home-banner .mobile-section-selected {
  display: none;
}
.career-gennie-web-button-mobile{
  display: none;
}

@media screen and (max-width: 1440px) {
  .background-gradient-2 {
 right: 0%;
  }
}

@media screen and (max-width: 1024px) {
  .home-banner .row {
    width: 90%;
  }
  .background-gradient-2 {
    right: 0%;
  }
}

/* Media query for tablets */

@media screen and (max-width: 768px) {

  .home-banner .background-gradient,
  .home-banner .background-gradient-2 {
    width: 80%;
    left: 0;
  }

  .home-banner {
    padding-top: 60px;
  }

  .home-banner .row .column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home-banner .row {
    flex-direction: column;
    row-gap: 20px;
  }

  .home-banner .row .column p {
    display: none;
  }

  .home-banner .row .column h4 {
    text-align: center;
  }

  .home-banner .row .column .only-mobile .companies {
    justify-content: center;
    margin-top: 0;
    align-items: center;
    column-gap: 20px;
    margin-bottom: 20px;
    width: 100%;
  }

  .home-banner .row .column2 {
    width: 100%;
    height: 27rem;
  }

  .home-banner .background-gradient {
    position: absolute;
    left: -5%;
    top: 20%;
    overflow: hidden;
  }
}



/* for mobile view */

@media screen and (max-width: 450px) {

  .home-banner .background-gradient,
  .home-banner .background-gradient-2 {
    width: 80%;
    left: 0;
  }

  .home-banner {
    padding-top: 60px;
  }

  .home-banner .row {
    width: 90%;
  }
  .home-banner .mobile-section-selected h4 {
    font-family: "Inter";
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: 700;
    color: rgb(92, 92, 92);
  }

  .cgwb-hide{
    display: none;
  }
  .career-gennie-web-button-mobile{
    display: block;
  }
  .home-banner .mobile-section-selected {
    align-items: center;
    column-gap: 20px;
    display: flex;
    margin-top: -5px;
    width: 100%;
    flex-direction: column;
  }

  .home-banner .mobile-section-selected .companies {

    align-items: center;
    column-gap: 20px;
    display: flex;
    margin-bottom: 20px;
    width: 80%;
    justify-content: center;

  }

  .home-banner .mobile-section-selected img {
    width: 30%;
  }

  .home-banner .row .column .only-tblt {
    display: none;
  }

  .home-banner .row .column2 {
    zoom: 0.86;
    margin-top: -5%;
  }

}