.applicant-tracking-resume-template {
    width: 100%;
    min-height: 1120px;
    position: relative;
    padding: 30px;
    text-align: left;
    background-color: white;
    background-size: cover;
    background-position: right;

}

.applicant-tracking-resume-template .resume-qr-code {
    position: absolute;
}

.applicant-tracking-resume-template h1 {
    font-size: 30px;
    margin-bottom: 5px;
}

.applicant-tracking-resume-template h4 {
    margin-bottom: 10px;
}

.applicant-tracking-resume-template .summary-information p {
    padding-bottom: 5px;
}

.applicant-tracking-resume-template h2 {
    margin: 10px 0px 5px 0px;
    text-transform: uppercase;
}

.applicant-tracking-resume-template .contact-information ul li {
    list-style: none;
    font-weight: 500;
    display: flex;
}

.applicant-tracking-resume-template .skills-information ul li {
    list-style: none;
    font-weight: 500;
}

.applicant-tracking-resume-template .contact-information {
    background-color: #f1f1f1;
    padding: 10px 30px;
    position: relative;
    width: calc(100% + 60px);
    left: -30px;
}

.applicant-tracking-resume-template .contact-information ul {
    display: flex;
    gap: 10px 30px;
    flex-wrap: wrap;
}

.applicant-tracking-resume-template .contact-information ul li p {
    margin-top: 3px;
    align-items: center;
}

.applicant-tracking-resume-template .contact-information i {
    margin-right: 6px;
    font-size: 14px;
    border-radius: 50%;
    padding: 12px;
    width: 15px;
    height: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.applicant-tracking-resume-template .common-section .common-details {
    padding-bottom: 8px;
}

.applicant-tracking-resume-template .common-section .common-details:nth-last-child(1) {
    padding-bottom: 0px;
}


.applicant-tracking-resume-template .skills-information ul {
    display: flex;
    gap: 12px 20px;
    flex-wrap: wrap;
}

.applicant-tracking-resume-template .skills-information li {
    background-color: #f1f1f1;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
}

.applicant-tracking-resume-template .hobbies-information ul {
    display: flex;
    gap: 12px 20px;
    flex-wrap: wrap;
}

.applicant-tracking-resume-template .hobbies-information li {
    background-color: #f1f1f1;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
    list-style: none;
}

.applicant-tracking-resume-template .languages-information ul {
    display: flex;
    gap: 12px 20px;
    flex-wrap: wrap;
}

.applicant-tracking-resume-template .languages-information li {
    background-color: #f1f1f1;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
    list-style: none;

}