.job-exp .month-year-pickers {
    position: relative;
    width: 100%;
}

.job-exp .start-end-date {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    outline: none;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    width: 100%;
    font-family: "mulish", sans-serif;
    background-color: #fff;
    cursor: pointer;
    font-size: 14px;
}

.job-exp .job-exp-section-pic-date {
    display: flex;
    gap: 20px;
    width: 100%;
    margin-bottom: 15px;
}

.job-exp-datepic-monthdate {
    display: flex;
    width: 100%;
    gap: 20px;
}

.job-exp-datepic {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 20px;
}

.job-exp-del-add-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.job-exp-del-add-button i {
    background-color: #fff;
    color: #333;
    font-size: 15px;
    font-weight: 600;
    border-radius: 10px;
    padding: 10px 15px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;

}

.job-exp .job-exp-content .job-exp-del-add-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    margin: 0;
}

.job-exp .job-exp-content:nth-last-child(1) .job-exp-del-add-button {
    padding-bottom: 0;
}

.job-exp-del-add-button i {
    background-color: #fff;
    color: #333;
    font-size: 15px;
    font-weight: 600;
    border-radius: 50%;
    padding: 10px 13px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;

}

.job-exp-del-add-button {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.job-exp-del-add-button hr {
    width: 93%;
    border: none;
    height: 1px;
    background-color: #ccd2f9;
}

.job-exp .textarea-delete-section {
    position: relative;
}

@media screen and (max-width: 450px) {
    .job-exp .job-exp-datepic-monthdate {
        flex-wrap: wrap;
    }
}