.cg-admin-text-editor {
    margin-top: 5px;
}
.cg-admin-text-editor .ql-picker-label{
    color: #d9d9d9;
    font-weight: 550;
    /* transition: transform 0.2s ease, stroke 0.2s ease; */
}

.cg-admin-text-editor .quill:focus-within .ql-picker-label {
    color: #000;
    font-weight: 550;
    transition: transform 0.3s ease, stroke 0.4s ease;
}

.cg-admin-text-editor .ql-toolbar.ql-snow{
    display: flex;
}
.cg-admin-text-editor .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options{
    background-color: #fff;
}
.cg-admin-text-editor .ql-toolbar.ql-snow .ql-formats{
    gap: 0;
    margin-right: 0;
}
.cg-admin-text-editor .ql-editor{
    background-color: #fafafa;
    position: relative;
}
.cg-admin-text-editor .ql-editor::before {
    font-family: 'inter';
  }
.cg-admin-text-editor .ql-editor p{ 
    color: #333;
    font-size: 14px;
}
.cg-admin-text-editor .ql-snow .ql-tooltip{
    left: 15px !important;
    top: 15px !important;
    position: relative;
    width: 36%;
}