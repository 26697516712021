.heading-border-resume-template {
    width: 100%;
    min-height: 1120px;
    position: relative;
    padding: 30px;
    background-color: white;
    background-size: cover;
    background-position: right;
}

.heading-border-resume-template .resume-qr-code {
    position: absolute;
}

.heading-border-resume-template .main-header {
    display: flex;
    justify-content: space-between;
    gap: 2%;
}

.heading-border-resume-template h1 {
    font-size: 30px;
    margin-bottom: 5px;
}

.heading-border-resume-template h4 {
    /* font-size: 14px; */
    margin-bottom: 5px;
}

.heading-border-resume-template p {
    /* font-size: 10px; */
    /* line-height: 18px; */
    text-align: left;
}

.heading-border-resume-template b {
    font-size: 12px;
}

.heading-border-resume-template .summary-information {
    margin-bottom: 10px;
}

.heading-border-resume-template h2 {
    font-size: 14px;
    text-align: left;
    margin: 10px 0px 10px 0px;
    padding: 5px 15px;
    text-transform: uppercase;
    background-color: #f6ebcf;
}

.heading-border-resume-template .personal-details {
    text-align: left;
    width: 59%;
}

.heading-border-resume-template .contact-details ul li {
    list-style: none;
    margin-bottom: 8px;
    font-weight: 500;
    display: flex;
    flex-direction: row-reverse;
}

.heading-border-resume-template .contact-details {
    width: 39%;
}

.heading-border-resume-template .contact-details li p {
    text-align: right;
}

.heading-border-resume-template .contact-details i {
    margin-top: 5px;
    margin-left: 10px;
}


.heading-border-resume-template .skills-information ul li {
    list-style: none;
    font-size: 10px;
    font-weight: 500;
    /* background-color: #fcf9f0; */
    padding: 5px 10px;
}

.heading-border-resume-template .skills-information ul {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 20px;
}

.heading-border-resume-template .hobbies-information ul li {
    list-style: none;
    font-size: 10px;
    font-weight: 500;
    padding: 5px 10px;
    /* border-radius: 5px; */
}

.heading-border-resume-template .hobbies-information ul {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 20px;
}

.heading-border-resume-template .common-sec .common-details {
    display: flex;
    justify-content: space-between;
}

.heading-border-resume-template .common-sec .common-level {
    padding-bottom: 8px;
}

.heading-border-resume-template .common-sec .common-level:nth-last-child(1) {
    padding-bottom: 0px;
}

.heading-border-resume-template .languages-information ul li {
    list-style: none;
    font-size: 10px;
    font-weight: 500;

    padding: 5px 10px;
    /* border-radius: 5px; */
}

.heading-border-resume-template .languages-information ul {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 20px;
}