.education-form .month-year-pickers {
    position: relative;
    width: 100%;
}

.education-form .start-end-date {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    outline: none;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    width: 100%;
    font-family: "mulish", sans-serif;
    background-color: #fff;
    cursor: pointer;
    font-size: 14px;
}

.education-form-section-pic-date {
    display: flex;
    gap: 20px;
    width: 100%;
    margin-bottom: 15px;
}

.education-del-add-button {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
}

.education-del-add-button i {
    background-color: #fff;
    color: #333;
    font-size: 15px;
    font-weight: 600;
    border-radius: 50%;
    padding: 10px 13px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.education-form .education-content .education-del-add-button {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.education-form .education-content:nth-last-child(1) .education-del-add-button{
    padding-bottom: 0;
}

.education-del-add-button hr {
    width: 93%;
    border: none;
    height: 1px;
    background-color: #ccd2f9;
}

.education-form .education-textarea-delete-section {
    position: relative;
}

@media screen and (max-width: 450px) {
    .education-form-section-pic-date {
        flex-wrap: wrap;
    }
}