.blog-page-vertical {
  width: 75%;
}

.blog-page-vertical h2 {
  font-family: "Montserrat";
}

.blog-page-vertical .blog-header p {
  font-size: 1.2em;
  color: #495057;
}


.blog-page-vertical .blog-main {
  width: 100%;
  padding-top: 20px;
}

.blog-page-vertical .blog-post-link {
  width: 100%;
}

.blog-page-vertical .blog-post {
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #e7e7e7;
  margin-bottom: 20px;
}

.blog-page-vertical .blog-post:hover {
  transform: translateY(-5px);
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); */
}

.blog-page-vertical .post-image {
  width: 100%;
  height: 180px;
  border-top: 0px;
  object-fit: cover;
}

.blog-page-vertical .content {
  padding: 15px;
}

.blog-page-vertical .post-title {
  font-size: 18px;
  color: #333;
  /* margin: 0 0 10px; */
  font-weight: 600;
  font-family: 'Montserrat';
}

.blog-page-vertical .post-date, .blog-page-vertical .post-author {
  font-size: 15px;
  color: #495057;
  margin: 0 0 8px;
  font-family: "Mulish";
  font-weight: 600;
}

.blog-page-vertical .read-more {
  background: none;
  border: none;
  color: #5a4abd;
  font-size: 14px;
  padding: 0px 0px 6px 0px;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  outline: none;
  font-weight: 600;
  font-family: "Montserrat";
}

.blog-page-vertical .read-more::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #5a4abd;
  transition: width 0.3s ease;
}

.blog-page-vertical .read-more:hover::after {
  width: 100%;
}

.blog-page-vertical .blog-post:hover .read-more::after {
  width: 100%;
}

.blog-page-vertical .blog-page-vertical-header {
  display: flex;
  width: 100vw;
  justify-content: center;
  position: relative;
  padding: 80px 0px;
}

.blog-page-vertical .blog-page-vertical-header .blog-text-heading {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 90%;
}

.blog-page-vertical-header button {
  border: none;
  padding: 12px 60px;
  font-size: 18px;
  font-weight: 300;
  background: linear-gradient(90deg, rgba(112, 92, 236, 1) 0%, rgba(42, 81, 186, 1) 100%);
  color: #fff;
  font-family: "Mulish";
  border-radius: 10px;
}

.blog-page-vertical-header button:hover {
  background: linear-gradient(90deg, rgba(42, 81, 186, 1) 0%, rgba(112, 92, 236, 1) 100%);
}

.blog-page-vertical .blog-page-vertical-row-section {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1400px;
  width: 80%;
  padding: 50px;
  background-color: #ECF2FF;
  border-radius: 15px;
}

.blog-page-vertical .blog-page-vertical-row-section #img1 {
  transform: rotate(7deg);
  margin-bottom: 10rem;
}

.blog-page-vertical .blog-page-vertical-row-section #img2 {
  margin-top: 10.4rem;
}

/* RESPONSIVE STYLES */

@media screen and (max-width: 1280px) {
  .blog-page-vertical-header button {
    width: 40%;
  }
}

@media screen and (max-width: 1024px) {
  .blog-page-vertical .blog-page-vertical-row-section {
    padding: 35px;
    width: 90%;
  }
  
  .blog-page-vertical-header button {
    width: 50%;
  }

  .blog-page-vertical .blog-main {
    width: 100%;
    justify-content: space-between;
   
  }

  .blog-page-vertical {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .blog-page-vertical .blog-page-vertical-header {
    padding: 60px 0px;
  }

  .blog-page-vertical-header button {
    width: 58%;
    padding: 15px;
  }

  .blog-page-vertical .post-image {
    width: 100%;
    height: 200px;
  }

  .blog-page-vertical .blog-page-vertical-header .blog-text-heading {
    width: 100%;
  }

  .blog-page-vertical .blog-post-link {
    width: 48%;
  }
}

@media screen and (max-width: 600px) {
  .blog-page-vertical .blog-page-vertical-row-section {
    padding: 20px;
    width: 90%;
  }

  .blog-page-vertical .blog-post-link {
    width: 90%;
    display: flex;
    justify-content: center;
  }

  .blog-page-vertical .blog-page-vertical-row-section #img1 {
    display: none;
  }

  .blog-page-vertical .blog-page-vertical-row-section #img2 {
    display: none;
  }

  .blog-page-vertical .blog-page-vertical-header .blog-text-heading {
    width: 100%;
  }

  .blog-page-vertical .post-title {
    font-size: 22px;
    margin: 0;
  }

  .blog-main .read-more {
    display: flex;
    font-size: 16px;
    width: 100%;
  }

  .blog-page-vertical .blog-post:hover .read-more::after {
    width: 77%;
  }

  .blog-page-vertical-header button {
    padding: 10px;
    font-size: 12px;
    width: 60%;
  }
}

@media screen and (max-width: 450px) {
  .blog-page-vertical .blog-main {
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }

  .blog-page-vertical .blog-page-vertical-header {
    padding: 40px 0px;
  }

  .blog-page-vertical .blog-page-vertical-row-section {
    padding: 20px;
    width: 90%;
  }

  .blog-page-vertical .blog-post-link {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .blog-page-vertical .post-title {
    font-size: 20px;
    margin: 0;
    margin-bottom: 8px;
  }

  .blog-main .read-more {
    display: flex;
    font-size: 16px;
    width: 45%;
  }

  .blog-page-vertical .blog-post:hover .read-more::after {
    width: 77%;
  }

  .blog-page-vertical-header button {
    padding: 10px;
    font-size: 12px;
    width: 60%;
  }
}
