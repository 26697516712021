.cg-admin-leftbar{
    position: fixed;
    background-color: #252046;
    height: calc(100vh - 45px);
   font-family: 'inter';
   color: #fff;
   /* padding-top: 20px; */
}
.cg-admin-leftbar .cg-leftbar-li{
    text-decoration: none;
    padding: 10px 20px;
    list-style: none;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255 ,255,0.1);
    font-weight: 300;
    font-size: 14px;
    display: flex;
    color: #fff;
    gap: 8px;
    align-items: center;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.cg-admin-leftbar .cg-leftbar-li:hover{
    background-color: rgba(255, 255, 255, 0.09) ;
}

.cg-admin-leftbar .cg-leftbar-li.active {
  
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
    text-decoration: none;
    border-left: 4px solid #fff;

}
.cg-admin-leftbar .cg-leftbar-li.active i{
    font-weight: 600;
}

/* 
.cg-leftbar-li.active:hover {
    background-color: #ededed; 
} */
