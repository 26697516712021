 .career-changer-resume {
     width: 100%;
     min-height: 1120px;
     position: relative;
     padding: 30px;
     background-color: white;
     background-size: cover;
     background-position: right;
 }

 .career-changer-resume .resume-qr-code {
     position: absolute;
     top: 10px;
     left: 10px;
 }

 .career-changer-resume h1 {
     font-size: 30px;
     text-align: center;
 }

 .career-changer-resume h4 {
     font-size: 14px;
     text-align: center;
     margin-bottom: 5px;
 }

 .career-changer-resume h2 {
     margin: 10px 0px 10px 0px;
     border-bottom: 2px solid #e5e5e5;
     text-align: left;
     text-transform: uppercase;
 }

 .career-changer-resume .contact-information ul {
     display: flex;
     flex-wrap: wrap;
     column-gap: 35px;
     row-gap: 6px;
     justify-content: center;
 }

 .career-changer-resume .contact-information ul li {
     list-style: disc;
 }

 .career-changer-resume .skills-information ul {
     display: flex;
     flex-wrap: wrap;
     column-gap: 30px;
     row-gap: 5px;
 }

 .career-changer-resume .skills-information li {
     width: 20%;
     list-style: inside;
     text-align: left;
     text-indent: -1.5em;
     padding-left: 1.5em;
 }

 .career-changer-resume .hobbies-information ul {
     display: flex;
     flex-wrap: wrap;
     column-gap: 30px;
     row-gap: 5px;
   
 }

 .career-changer-resume .hobbies-information li {
     width: 20%;
     list-style: inside;
     text-align: left;
     text-indent: -1.5em;
     padding-left: 1.5em;
 }

 .career-changer-resume .common-section .common-details {
     display: flex;
     justify-content: space-between;
 }

 .career-changer-resume .common-section .common-level {
     padding-bottom: 5px;
 }
 .career-changer-resume .common-section .common-details  .all-name-section-wdt{
    width: 70%;
 }
 .career-changer-resume .common-section .common-details .all-name-section-date{
    width: 30%;
   text-align: end;
 }

 .career-changer-resume .common-section .common-level:nth-last-child(1) {
     padding-bottom: 0px;
 }

 .career-changer-resume .languages-information ul {
     display: flex;
     flex-wrap: wrap;
     column-gap: 30px;
     row-gap: 5px;
 }

 .career-changer-resume .languages-information li {
     width: 30%;
     list-style: inside;
     text-align: left;
     text-indent: -1.5em;
     padding-left: 1.5em;
 }