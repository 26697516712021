.change-template-box {
    width: 400px;
}

.change-template-box .all-templates {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    height: 350px;
    overflow-y: auto;
}

.change-template-box .each-template {
    width: 45%;
    zoom: 0.2;
    border: 40px solid #F0F2FD;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
}

.change-template-box .active-temp::before {
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(112, 92, 236, 0.95) 0%, rgba(31, 104, 232, 0.95) 100%);
    position: absolute;
    border-radius: 20px;
    z-index: 3;
}

.change-template-box .active-temp .active-icon {
    font-size: 120px;
    z-index: 4;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 450px) {
    .change-template-box {
        width: 350px;
    }

    .change-template-box .each-template {
        zoom: 0.18;
    }
}