.interest-form .interest-list {
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    outline: none;
}

.interest-form .interest-list input {
    padding: 10px;
    width: 100%;
    font-family: "mulish";
    border: none;
    outline: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.interest-form .interest-list .fa-trash {
    border-radius: 10px;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-left: 1px solid #e5e5e5;
    padding: 10px;
    cursor: pointer;
}