.box-style-resume {
    width: 100%;
    min-height: 1120px;
    position: relative;
    padding-right: 30px;
    display: flex;
    background-color: #fff;
    background-size: cover;
    padding-bottom: 30px;
}

.box-style-resume .resume-qr-code {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.box-style-resume .left-column .profile-img {
    width: 100%;
    height: 260px;
    background-position: center;
    background-size: cover;
}

.box-style-resume .left-column {
    width: 40%;
    background-color: #fafafa;
    padding-left: 30px;
}

.box-style-resume h2 {
    margin: 10px 0px 10px 0px;
    text-transform: uppercase;
}

.box-style-resume .summary-information {
    padding-right: 10px;
}

.box-style-resume .common-list-styling {
    margin-right: 10px;
}

.box-style-resume .common-list-styling ul li {
    list-style: circle;
    list-style-position: inside;
    margin-bottom: 6px;
    font-weight: 500;
    text-indent: -1.5em;
    padding-left: 1.5em;
}

.box-style-resume .common-list-stylingul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.box-style-resume .left-column .project-information {
    margin-right: 10px;
}

.box-style-resume .left-column .project-information .project-details {
    display: flex;
    /* justify-content: space-between; */
    column-gap: 3%;
    padding-bottom: 8px;
}

.box-style-resume .left-column .project-information .project-details .cmn-date {
    width: 23%;
}

.box-style-resume .left-column .project-information .project-details:nth-last-child(1) {
    padding-bottom: 0px;
}

.box-style-resume .left-column .project-information .project-details div {
    width: 77%;
}

.box-style-resume h1 {
    font-size: 35px;
    margin-bottom: 5px;
}

.box-style-resume h4 {
    font-size: 14px;
}

.box-style-resume .right-column {
    width: 60%;
    padding-top: 30px;
    padding-left: 20px;
}

.box-style-resume .right-column .personal-details {
    background-color: #C4DAD2;
    padding: 20px 0px 20px 20px;
    position: relative;
    width: calc(100% + 50px);
    left: -20px;
}

.box-style-resume .right-column .contact-information ul li {
    list-style: none;
    display: flex;
    font-weight: 500;
}

.box-style-resume .right-column .contact-information ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 20px;
}

.box-style-resume .right-column .contact-information i {
    margin-right: 8px;
    font-size: 10px;
    padding: 10px;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.box-style-resume .right-column .education-information .education-details {
    display: flex;
    column-gap: 3%;
}

.box-style-resume .right-column .education-information .education-details .cmn-date {
    width: 20%;
}

.box-style-resume .right-column .education-information .education-details .cmmn-sec-border {
    width: 77%;
}

.box-style-resume .right-column .cmmn-sec-border {
    border-left: 1px solid #000;
    padding-left: 25px;
    position: relative;
}

.box-style-resume .right-column .cmmn-sec-border .list-dots {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: -5px;
    border: 1px solid #333;
    background-color: #fff;
}

.box-style-resume .right-column .experience-information .experience-details {
    display: flex;
    column-gap: 3%;
}

.box-style-resume .right-column .experience-information .experience-details .cmn-date {
    width: 20%;
}

.box-style-resume .right-column .experience-information .experience-details div {
    width: 77%;
}

.box-style-resume .right-column .certification-information .certification-details {
    display: flex;
    column-gap: 3%;
}

.box-style-resume .right-column .certification-information .certification-details .cmn-date {
    width: 20%;
}

.box-style-resume .right-column .certification-information .certification-details div {
    width: 77%;
}