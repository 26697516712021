.colorfull-resume-template {
    width: 100%;
    min-height: 1120px;
    position: relative;
    display: flex;
    gap: 5%;
    padding: 30px 30px;
    background-size: cover;
    background-color: white;
    background-position: right;
}

.colorfull-resume-template .resume-qr-code {
    position: absolute;
}

.colorfull-resume-template .left-column {
    width: 40%;
}

.colorfull-resume-template .left-column .profile-img {
    width: 185px;
    height: 185px;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border: 2px solid #000000;
}

.colorfull-resume-template h2 {
    margin: -20px 0px 10px 0px;
    border: 2px solid #000000;
    padding: 5px 10px;
    border-radius: 30px;
    z-index: 2;
    width: 80%;
}

.colorfull-resume-template .right-column h1 {
    font-size: 45px;
    line-height: 18px;
    margin: 10px 0px 20px 0px;
}

.colorfull-resume-template .right-column h4 {
    font-size: 18px;
}

.colorfull-resume-template .contact-details ul li {
    list-style: none;
    color: #000000;
    margin-bottom: 8px;
    text-align: left;
    font-size: 12px;
    display: flex;
    font-weight: 500;

}

.colorfull-resume-template .left-column .contact-details {
    border: 2px solid black;
    padding: 0px 20px 10px 20px;
    border-radius: 20px;
    margin-top: 50px;
}

.colorfull-resume-template .left-column .contact-details ul a:nth-last-child(1) li {
    margin-bottom: 0px;
}

.colorfull-resume-template .left-column .contact-details h2 {
    background-color: #b3cdff;
    text-transform: uppercase;
}

.colorfull-resume-template .left-column .contact-details i {
    margin-right: 15px;
    font-size: 12px;
    background-color: #b3cdff;
    border-radius: 50%;
    color: #000000;
    padding: 12px;
    width: 18px;
    height: 18px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.colorfull-resume-template .left-column .education-information {
    border: 2px solid black;
    padding: 0px 20px 10px 20px;
    border-radius: 20px;
    margin-top: 40px;
}

.colorfull-resume-template .left-column .education-information h2 {
    background-color: #9CC5A1;
    text-transform: uppercase;
}

.colorfull-resume-template .left-column .education-information .education-details {
    padding-bottom: 8px;
}

.colorfull-resume-template .left-column .education-information .education-details:nth-last-child(1) {
    padding-bottom: 0px;
}

.colorfull-resume-template .left-column .education-information .education-details i {
    color: #f73f45;
}

.colorfull-resume-template .hobbies-information ul li {
    list-style: none;
    margin-bottom: 6px;
    text-align: left;
    list-style: circle;
    width: 100%;
    list-style-position: inside;
    font-weight: 500;
    text-indent: -1.5em;
    padding-left: 1.5em;
}

.colorfull-resume-template .left-column .hobbies-information {
    border: 2px solid black;
    padding: 0px 20px 10px 20px;
    border-radius: 20px;
    margin-top: 40px;
}

.colorfull-resume-template .left-column .hobbies-information h2 {
    background-color: #fdf0d5;
    text-transform: uppercase;
}

.colorfull-resume-template .left-column .hobbies-information ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4%;

}

.colorfull-resume-template .left-column .hobbies-information ul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.colorfull-resume-template .skills-information ul li {

    margin-bottom: 6px;
    text-align: left;
    list-style: circle;
    width: 100%;
    list-style-position: inside;
    font-weight: 500;
    text-indent: -1.5em;
    padding-left: 1.5em;
}

.colorfull-resume-template .left-column .skills-information {
    border: 2px solid black;
    padding: 0px 20px 10px 20px;
    border-radius: 20px;
    margin-top: 40px;
}

.colorfull-resume-template .left-column .skills-information h2 {
    background-color: #e5e5e5;
    text-transform: uppercase;
}

.colorfull-resume-template .left-column .skills-information ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4%;
}

.colorfull-resume-template .left-column .skills-information ul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.colorfull-resume-template .right-column {
    width: 55%;
}

.colorfull-resume-template .right-column .summary-information {
    margin-top: 20px;
}

.colorfull-resume-template .right-column .experience-information {
    border: 2px solid black;
    padding: 0px 20px 10px 20px;
    border-radius: 20px;
    margin-top: 40px;
}

.colorfull-resume-template .right-column .experience-information h2 {
    background-color: #8cdff8;
    text-transform: uppercase;
}

.colorfull-resume-template .right-column .experience-information .experience-details {
    padding-bottom: 8px;
}

.colorfull-resume-template .right-column .experience-information .experience-details:nth-last-child(1) {
    padding-bottom: 0px;
}

.colorfull-resume-template .right-column .experience-information .experience-details i {
    color: #8cdff8;
}

.colorfull-resume-template .right-column .project-information {
    border: 2px solid black;
    padding: 0px 20px 10px 20px;
    border-radius: 20px;
    margin-top: 40px;
}

.colorfull-resume-template .right-column .project-information h2 {
    background-color: rgb(126, 116, 255);
    text-transform: uppercase;
}

.colorfull-resume-template .right-column .project-information .project-details {
    padding-bottom: 8px;
}

.colorfull-resume-template .right-column .project-information .project-details:nth-last-child(1) {
    padding-bottom: 0px;
}

.colorfull-resume-template .right-column .project-information .project-details i {
    color: rgb(126, 116, 255);
}

.colorfull-resume-template .right-column .certification-information {
    border: 2px solid black;
    padding: 0px 20px 10px 20px;
    border-radius: 20px;
    margin-top: 40px;
}

.colorfull-resume-template .right-column .certification-information h2 {
    background-color: #ffc2d1;
    text-transform: uppercase;
}

.colorfull-resume-template .right-column .certification-information .certification-details {
    padding-bottom: 8px;
}

.colorfull-resume-template .right-column .certification-information .certification-details:nth-last-child(1) {
    padding-bottom: 0px;
}

.colorfull-resume-template .right-column .certification-information .certification-details i {
    color: #ffc2d1;
}

.colorfull-resume-template .languages-information ul li {
    margin-bottom: 6px;
    text-align: left;
    list-style: circle;
    width: 100%;
    list-style-position: inside;
    font-weight: 500;
    text-indent: -1.5em;
    padding-left: 1.5em;
}

.colorfull-resume-template .right-column .languages-information {
    border: 2px solid black;
    padding: 0px 20px 10px 20px;
    border-radius: 20px;
    margin-top: 40px;
}

.colorfull-resume-template .right-column .languages-information h2 {
    background-color: rgb(238, 177, 47);
    text-transform: uppercase;
}

.colorfull-resume-template .right-column .languages-information ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4%;
}

.colorfull-resume-template .right-column .languages-information ul li:nth-last-child(1) {
    margin-bottom: 0px;
}