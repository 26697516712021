.text-color-popup h4{
    font-family: "inter";
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 600;
    color: rgb(92, 92, 92);
}

.text-color-popup .color-input{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.text-color-popup input{
    padding: 10px;
    outline: 1px solid #e5e5e5;
    border-radius: 5px;
    border: none;
    margin-bottom: 15px;
}

.text-color-popup input:focus{
    outline: 1px solid #5a4abd;
}

.text-color-popup .color-input label{
    width: 40px;
    height: 35px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    cursor: pointer;
}

.text-color-popup input[type="color"] {
    display: none;
}