.pattern-style-resume {
    width: 100%;
    background-color: #fff;
    background-size: cover;
    min-height: 1120px;
    position: relative;
    padding: 40px;
    z-index: 1;
    background-position: right;
}

.pattern-style-resume .resume-qr-code {
    position: absolute;
}

.pattern-style-resume .header {
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
}

/* Global Css  */

.pattern-style-resume h1 {
    font-size: 35px;
    margin-bottom: 5px;
    padding-top: 60px;
}

.pattern-style-resume .personal-details {
    width: 60%;
}

.pattern-style-resume h4 {
    font-size: 14px;
    position: relative;
}

.pattern-style-resume h4 .bottom-theme {
    height: 10px;
    width: 300px;
    top: 25px;
    position: absolute;
    margin-top: -12px;
    z-index: -1;
}

.pattern-style-resume .summary-information {
    margin-top: 25px;
}

.pattern-style-resume .profile-img {
    width: 200px;
    height: 160px;
    background-position: center;
    margin-top: 18px;
    background-size: cover;
    position: relative;
}

.pattern-style-resume .profile-img .side-theme {
    height: 270px;
    width: 85px;
    position: absolute;
    z-index: -1;
    top: -58px;
    right: -24px;
}

.pattern-style-resume h2 {
    text-transform: uppercase;
    border-bottom: 1px solid;
    display: inline-block;
    margin: 10px 0px 6px 0px;
}

.pattern-style-resume .main {
    width: 100%;
    display: flex;
    margin-top: 20px;
}

.pattern-style-resume .left-column {
    width: 40%;
    padding-right: 20px;
}

/* Education information css */

.pattern-style-resume .common-section .common-details i {
    display: inline-block;
}

.pattern-style-resume .common-details {
    padding-bottom: 6px;
}

.pattern-style-resume .common-details :nth-last-child(1) {
    padding-bottom: 0px;
}

.pattern-style-resume .common-list-styling ul li {
    list-style: none;
    margin-bottom: 6px;
    font-weight: 500;
    position: relative;
    padding-left: 20px;
}

.pattern-style-resume .common-list-styling ul li:nth-last-child(1) {
    margin-bottom: 0px;
}

.pattern-style-resume .common-list-styling .list-dots {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    bottom: 10px;
    top: 6.5px;
    left: 0px;
}

.pattern-style-resume .right-column {
    width: 60%;
}

.pattern-style-resume .main .right-column {
    border-left: 2px solid;
    padding-left: 20px;
}

/* contact information Css */

.pattern-style-resume .contact-Information {
    border-top: 2px solid;
    margin: 30px 0px 0px 0px;
    position: relative;
}

.pattern-style-resume .contact-Information .contact-theme-design {
    width: 60%;
    height: 20px;
    position: absolute;
    right: 0px;
    top: -12px;
}

.pattern-style-resume .contact-Information ul li {
    display: flex;
    list-style: none;
    padding-top: 20px;
    font-weight: 500;
}

.pattern-style-resume .contact-Information ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 5px;
}

.pattern-style-resume .contact-Information i {
    margin-right: 10px;
    font-size: 12px;
    padding: 10px;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}