.header-hamburger-section {
  width: 100vw;
  height: calc(100vh - 77px);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  position: fixed;
  top: 77px;
  left: 0;
  z-index: 99;
}

/* Hamburger Menu */
.header-hamburger-section .hamburger-menu {
  width: 55%;
  height: 100%;
  background-color: #fff;
  position: relative;
  animation: RightSliding 0.3s linear;
}

.header-hamburger-section .cancel-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
}

.header-hamburger-section .hamburger-menu-logo h2 {
  text-align: left;
  margin: 20px 0px 30px 0px;
  font-family: 'Montserrat', sans-serif;
  color: black;
  font-size: 5vmax;
  font-weight: bold;
  padding-left: 18px;

}

.header-hamburger-section .hamburger-menu-items {
  list-style: none;
  padding-left: 0;
}

.header-hamburger-section .hamburger-menu-items li {
  display: flex;
  font-family: "Mulish";
  border-bottom: 1px solid #e5e5e5;
  padding: 20px;

}

.header-hamburger-section .hamburger-menu-items li:hover {
  background: #f2f2f2;
}

.header-hamburger-section .hamburger-menu-items li svg {
  margin-right: 10px;
  color: black;
  width: 20px;
  height: 20px;
}

.header-hamburger-section .login-signin-section {
  position: absolute;
  width: 100%;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  bottom: 10px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 30px;
  padding: 20px 0;
  animation: RightSliding 0.3s linear;
}

.header-hamburger-section .login-signin-section button {
  border: none;
  border-radius: 8px;
  font-family: 'Inter';
  font-weight: 500;
  transition: 0.3s;
  padding: 15px 50px;
}

.header-hamburger-section .login-signin-section button a {
  text-decoration: none;
}

.header-hamburger-section .login-signin-section .login-btn {
  border: 2px solid #5a4abd;
  background-color: transparent;
  color: #fff;
  outline: 1px solid #fff;
  color: #5a4abd;
}

.header-hamburger-section .login-signin-section .signup-btn {
  color: #fff;
  background: linear-gradient(90deg, rgba(112, 92, 236, 1) 0%, rgba(42, 81, 186, 1) 100%);
}

@media screen and (max-width: 768px) {
  .header-hamburger-section .hamburger-icon {
    display: block;
  }

  .header-hamburger-section .hamburger-logo {
    display: none;
  }

  .header-hamburger-section .login-signin-section {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .header-hamburger-section {
    height: calc(100vh - 120px);
  }

  .header-hamburger-section .hamburger-menu {
    width: 90%;
  }

  .header-hamburger-section .cancel-btn {
    top: 12px;
    right: 12px;
    font-size: 1.5rem;
  }

  .header-hamburger-section .hamburger-menu-logo h2 {
    font-size: 40px;
    margin: 20px 0px 20px 0px;
  }

  .header-hamburger-section .hamburger-menu-items li a {
    font-size: 16px;
  }

  .header-hamburger-section .login-signin-section {
    display: flex;
  }

}