.cg-upload-media-popup {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cg-upload-media-popup .popup-container {
  background-color: #fff;
  border-radius: 20px;
  width: 70%;
  padding: 0 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.cg-upload-media-popup .popup-container .popup-header {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: calc(100% + 60px);
  margin-left: -30px;
  background-color: #fafafa;
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
}

.cg-upload-media-popup .popup-container .popup-header h2 {
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #000;
}

.cg-upload-media-popup .popup-container .popup-header .cancel-btn {
  width: 22px;
  height: 22px;
  color: #000;
  cursor: pointer;
}

.cg-upload-media-popup .popup-container .popup-header .cancel-btn:hover {
  color: #5a4abd;
  animation: AnimOnCancelBtn 0.2s linear forwards alternate;
}

.cg-upload-media-popup .popup-container .search-media-box {
  position: relative;
}

.cg-upload-media-popup .popup-container .search-media-box i {
  font-size: 14px;
  color: #999;
  position: absolute;
  top: 14px;
  left: 180px;
  cursor: pointer;
}

.cg-upload-media-popup .popup-container .search-media-box input {
  font-size: 14px;
  border: none;
  outline: none;
  font-family: "inter";
  display: flex;
  align-items: center;
  border: 1px solid #e5e5e5;
  padding: 10px;
  column-gap: 10px;
  border-radius: 10px;
}

.cg-upload-media-popup .popup-container .search-media-box .search-input::placeholder {
  color: #999;
}

.cg-upload-media-popup .popup-container {
  text-align: center;
}

.popup-container .upload-box {
  border: 2px dashed #ccc;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 30px;
  margin-top: 30px;
  transition: background-color 0.3s ease;
}

.cg-upload-media-popup .popup-container .upload-box:hover {
  background-color: #f8f8f8;
}

.cg-upload-media-popup .popup-container .upload-box .upload-text {
  font-size: 16px;
  font-family: "inter";
  color: black;
  margin-bottom: 10px;
}

.cg-upload-media-popup .popup-container .upload-box .select-files-button {
  display: inline-block;
  padding: 10px 20px;
  font-family: "inter";
  background-color: #7872e2;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.cg-upload-media-popup .popup-container .upload-box .select-files-button:hover {
  background-color: #6757d4;
}

.cg-upload-media-popup .popup-container .upload-box input {
  display: none;
}

.cg-upload-media-popup .popup-container .upload-box .file-size-note {
  margin-top: 10px;
  font-family: "inter";
  color: #666;
  font-size: 12px;
}

.cg-upload-media-popup .popup-container .uploaded-images {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 20px;
  justify-content: left;
  position: relative;
}

.cg-upload-media-popup .popup-container .uploaded-images .media {
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;

}

.cg-upload-media-popup .popup-container .uploaded-images .media.active {
  background-color: #f2f2f2 !important;
}

.cg-upload-media-popup .popup-container .uploaded-images .media.active .image-preview::before {
  display: block;
}

.cg-upload-media-popup .popup-container .uploaded-images .media:hover {
  background-color: #fafafa;
}

.cg-upload-media-popup .popup-container .uploaded-images .media:hover .image-preview::before {
  display: block;
}

.cg-upload-media-popup .popup-container .uploaded-images .media .image-preview {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  margin-bottom: 10px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: 5px solid #fff;
  outline: 2px solid #e5e5e5;
  position: relative;
}

.cg-upload-media-popup .popup-container .uploaded-images .media .image-preview::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  inset: 0;
  border-radius: 5px;
  display: none;
}

.cg-upload-media-popup .popup-container .uploaded-images .media p {
  font-size: 14px;
  color: #333;
}

.cg-upload-media-popup .popup-container .uploaded-images .media .edit-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  opacity: 0;
  font-size: 22px;
  color: black;
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;

}

.cg-upload-media-popup .popup-container .uploaded-images .media .image-preview:hover .edit-icon {
  opacity: 1;
  transform: scale(1.2);

}

.cg-upload-media-popup .popup-container .cg-media-can-dne-btn {
  width: calc(100% + 60px);
  margin-left: -30px;
  border-top: 1px solid #d9d9d9;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 20px;
  display: flex;
  justify-content: end;
  gap: 20px;
}


.cg-upload-media-popup .popup-container .cg-media-can-dne-btn .can {
  background-color: #fafafa;
  border-radius: 5px;
  padding: 10px 20px;
}

.cg-upload-media-popup .popup-container .cg-media-can-dne-btn .sav {
  background-color: #7872e2;
  border-radius: 5px;
  padding: 10px 20px;
  color: #fff;
}

.cg-upload-media-popup .popup-container .cg-media-can-dne-btn .sav:hover {
  background-color: #6757d4;
}