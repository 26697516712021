.Show-blog-data {
    padding:0 40px;
}
.Show-blog-data h2{
    font-size: 32px;
    font-weight: 500;
}
.Show-blog-data .blog-lists{
    display: flex;
    gap: 30px;
    margin-top: 20px;
    border-bottom: 2px solid #e4e4e4;
    padding-bottom: 20px;
}

.Show-blog-data .blog-lists .bl-img{
    width: 350px;
    height: 180px;
}
.Show-blog-data .no-blog-p{
    margin-top: 15px;
    font-family: inter;
}
.Show-blog-data .blog-lists .bl-img img {
    object-fit: cover; 
    object-position: top; 
    width: 100%;  
    height: 100%;  
    border-radius: 6PX;
}
.Show-blog-data .blog-lists .details{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.Show-blog-data .blog-lists .details h3{
    font-size: 24px;
    font-family: "montserrat";
}
.Show-blog-data .blog-lists .details p{
    font-size: 14px;
    font-family: 'inter';
}
.Show-blog-data .blog-lists .details span{
display: flex;
gap: 10px;
font-style: italic;
font-weight: 500;
align-items: center;
font-family: 'inter';
}
.Show-blog-data .blog-lists .details .btn-groups {
    display: flex;
    gap: 18px;
    padding-top: 5px;
}
.Show-blog-data .blog-lists .details .btn-groups button {
    padding: 10px 25px;
    font-family: "inter";
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid rgba(37, 32, 70, 0.1);
    color: #000;
    font-weight: 500;
    transition: all 0.3s ease; 
}
.Show-blog-data .blog-lists .details .btn-groups button:hover{
    background-color: rgba(37, 32, 70, 0.15);
}
.Show-blog-data select {
outline: none;
margin-top: 12px;
font-size: 15px;
font-family: "inter";
padding: 2px 10px 2px 2px;
}
