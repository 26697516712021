.Three_Side_Border_Resume {
    width: 100%;
    min-height: 1120px;
    position: relative;
    display: flex;
    column-gap: 5%;
    background-color: #fff;
    background-size: cover;
    background-position: right;
}

.Three_Side_Border_Resume .resume-qr-code {
    position: absolute;
}

.Three_Side_Border_Resume h1 {
    text-transform: uppercase;
    font-size: 30px;
}

.Three_Side_Border_Resume h4 {
    text-transform: uppercase;
    font-size: 14px;
}

.Three_Side_Border_Resume h2 {
    margin: 10px 0px 5px 0px;
    text-transform: uppercase;
    padding-left: 20px;
}

.Three_Side_Border_Resume .Left_Column {
    width: 38%;
    padding-left: 30px;
    border-top: 25px solid;
}

.Three_Side_Border_Resume .Left_Column .Profile_Image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 10px solid #fff;
    outline: 1px solid;
    background-size: cover;
    background-position: center;
    margin-top: 25px;
    margin-bottom: 20px;
}

/* Conatct Information Css */

.Three_Side_Border_Resume .Left_Column .Contact_info {
    border-bottom: 1px solid;
    padding-bottom: 10px;
}

.Three_Side_Border_Resume .Left_Column .Contact_info ul li {
    list-style: none;
    margin-bottom: 5px;
    font-weight: 550;
    display: flex;
}

.Three_Side_Border_Resume .Left_Column .Contact_info ul a:nth-last-child(1) li {
    margin-bottom: 0px;
}

.Three_Side_Border_Resume .Left_Column .Contact_info i {
    width: 15px;
    height: 15px;
    padding: 8px;
    color: #fff;
    font-size: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 3px;
}

.Three_Side_Border_Resume .Left_Column .Contact_info ul {
    border-left: 1px solid;
    padding-left: 20px;
}

/* Skills Information Css */

.Three_Side_Border_Resume .Common-list-styling ul {
    border-left: 1px solid;
}

.Three_Side_Border_Resume .Common-list-styling {
    border-bottom: 1px solid;
    padding-bottom: 10px;
}

.Three_Side_Border_Resume .Common-list-styling ul li {
    padding-left: 20px;
    position: relative;
    list-style: none;
    font-weight: 550;
    margin-bottom: 5px;
}

.Three_Side_Border_Resume .dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    left: -2.9px;
    top: 6px;
}

/* project Information  */

.Three_Side_Border_Resume .Left_Column .Project_Information .Project_Type {
    border-left: 1px solid;
    padding-left: 20px;
    position: relative;
    padding-bottom: 5px;
}

.Three_Side_Border_Resume .Left_Column .Project_Information {
    border-bottom: 1px solid;
    padding-bottom: 10px;
}

.Three_Side_Border_Resume .Left_Column .Project_Information .Project_Type:nth-last-child(1) {
    padding-bottom: 0px;
}

/* Right Coloumn Personal Information */

.Three_Side_Border_Resume .Right_Column {
    width: 57%;
    padding-right: 30px;
    padding-top: 80px;
    padding-bottom: 80px;
}

.Three_Side_Border_Resume .Right_Column .Personal_Information {
    width: 115%;
    margin-left: -10%;
    background-color: #c7fffb;
    padding: 25px;
}

/* Summary Information css */

.Three_Side_Border_Resume .Right_Column .Summary_Information {
    border-bottom: 1px solid;
    padding-bottom: 10px;
    padding-top: 15px;
}

.Three_Side_Border_Resume .Right_Column .Summary_Information .para-theme {
    border-left: 1px solid;
    padding-left: 20px;
    padding-right: 20px;
}

/* Eduaction Information Css */

.Three_Side_Border_Resume .Right_Column .dot {
    left: -3px;
}

.Three_Side_Border_Resume .Right_Column .Common-Section .Common_Details {
    border-left: 1px solid;
    padding-left: 20px;
    position: relative;
    padding-bottom: 5px;
    padding-right: 20px;
}

/* Common-Section CSS  */

.Three_Side_Border_Resume .Right_Column .Common-Section {
    border-bottom: 1px solid;
    padding-bottom: 10px;
}

.Three_Side_Border_Resume .Right_Column .Common-Section .Common_level {
    display: flex;
    justify-content: space-between;
}

.Three_Side_Border_Resume .Right_Column .Common-Section .Common_level .overflow {
    width: 50%;
}

.Three_Side_Border_Resume .Right_Column .Common-Section .Common_Details:nth-last-child(1) {
    padding-bottom: 0px;
}

.Three_Side_Border_Resume .styling-dot {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    left: -3px;
    top: 10px;
}

.Three_Side_Border_Resume .Border_Gradient {
    height: 22px;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.Three_Side_Border_Resume .Border_Gradient_R {
    height: 50%;
    width: 22px;
    position: absolute;
    right: 0;
}