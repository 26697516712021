.blue-and-white-clean-resume {
    width: 100%;
    min-height: 1120px;
    padding: 30px;
    background-color: #fff;
    overflow-anchor: none;
    border: 1px solid #e5e5e5;
    background-size: cover;
    position: relative;
    background-position: right;
}

.blue-and-white-clean-resume .resume-qr-code {
    position: absolute;
}

.blue-and-white-clean-resume h1 {
    text-transform: uppercase;
    font-size: 30px;
}

.blue-and-white-clean-resume h2 {
    text-transform: uppercase;
    font-weight: 20%;
    padding-bottom: 2px;
    border-bottom: 1px solid #00366d1e;
    margin: 10px 0px 5px 0px;
}

.blue-and-white-clean-resume h4 {
    width: 100%;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 10px;
}

/* HEADER AND CONTACT SECTION CSS */

.blue-and-white-clean-resume .header {
    width: 100%;
    display: flex;
    margin-bottom: 30px;
}

.blue-and-white-clean-resume .header .img-module {
    width: 150px;
    height: 150px;
    background-size: cover;
}

.blue-and-white-clean-resume .header .personal-details-module {
    width: 80%;
    padding-left: 30px;
}

.blue-and-white-clean-resume .header .personal-details-module ul li {
    width: 100%;
    display: flex;
    column-gap: 10px;
    align-items: center;
    margin-bottom: 8px;
}

.blue-and-white-clean-resume .header .personal-details-module ul li i {
    font-size: 13px;
}

/* PROJECT AND JOB SECTION CSS */

.blue-and-white-clean-resume .common-sec {
    margin-bottom: 5px;
}

.blue-and-white-clean-resume .common-sec .name-date {
    display: flex;
    justify-content: space-between;
}

/* SKILL SECTION CSS */

.blue-and-white-clean-resume .common-list-styling li {
    list-style-type: circle;
    list-style-position: inside;
    list-style: none;
    margin-bottom: 6px;
}

.blue-and-white-clean-resume .common-list-styling li:nth-last-child(1) {
    margin-bottom: 0px;
}