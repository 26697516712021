.signup-body {
  width: 100vw;
  display: flex;
  /* align-items: flex-start; */
}

.signup-body .image-sec {
  width: 50%;
  /* min-height: 100vh; */
  background-image: url("../../assets/resume-images/Cv\ Nest\ Signup.png");
  background-size: cover;
  background-position: center;
}

/* SIGNUP BOX */

.signup-body .signup-column {
  padding: 50px;
  width: 50%;
  min-height: 100vh;
  padding-left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
}

.signup-body .signup-column .signup-box {
  width: 60%;
}


.signup-body .signup-column .signup-box .pass-eye {
  position: relative;
  width: 100%;
}

.signup-body .signup-column .signup-box .pass-eye input {
  width: 100%;
}

.signup-body .signup-column .signup-box .pass-eye i {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #635ae2;
  cursor: pointer;
}

.signup-body .signup-column .signup-box input {
  width: 100%;
  border-radius: 10px;
  outline: none;
  padding: 10px;
  border: 1px solid #e5e5e5;
  font-family: "mulish";
  font-size: 0.9rem;
  margin-bottom: 15px;
}

.signup-body .signup-column .signup-box button {
  width: 100%;
  font-family: "mulish";
  font-size: 1.1rem;
  padding: 12px 60px;
  font-weight: 300;
  background: linear-gradient(90deg,
    rgba(112, 92, 236, 1) 0%,
    rgba(42, 81, 186, 1) 100%);
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-family: "Inter";
}

.signup-body .signup-column .signup-box .login-up-btn:hover , .signup-body .signup-column .signup-box .sign-up-btn:hover{
  background: linear-gradient(90deg,
  rgba(42, 81, 186, 1) 0%,
  rgba(112, 92, 236, 1) 100%);
}

.signup-body .signup-column .signup-box .or-sec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px;
}

.signup-body .signup-column .signup-box .or-sec hr {
  width: 40%;
  height: 1px;
  border: none;
  background-color: #dbdbdb;
  margin-top: 1px;
}

.signup-body .signup-column .signup-box .signup-google {
  background: transparent;
  color: #333;
  font-family: "inter";
  border: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  padding: 12px 40px;
  margin-bottom: 15px;
}
.signup-body .signup-column .signup-box .signup-google img {
  width: 20px;
  height: 20px;
}

.signup-body .signup-column .login-signup-link {
  width: 100%;
}

.signup-body .signup-column .login-signup-link p a {
  color: #5A4ABD;
}

@media screen and (max-width: 1024px) {

  .signup-body .signup-column {
    width: 50%;
    min-height: auto;
    display: flex;
    padding-left: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .signup-body .signup-column .signup-box {
    width: 80%;

  }

  .signup-body .signup-column .signup-box input {
    width: 100%;
    padding: 10px;
    font-size: 0.7rem;
    margin-bottom: 15px;
  }

  .signup-body .signup-column .signup-box .pass-eye {
    width: 100%;
  }



  .signup-body .signup-column .signup-box .signup-google {
    width: 100%;
  }

  .signup-body .image-sec {
    width: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .signup-body .signup-column .signup-box .or-sec {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .signup-body {
    height: auto;
  }

  .signup-body .image-sec {
    display: none;
  }

  .signup-body .signup-column {
    width: 100%;
    padding: 60px 0;
    min-height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .signup-body .signup-column .signup-box {
    width: 70%;
    text-align: center;
  }

  .signup-body .signup-column .signup-box input {
    padding: 10px;
    font-size: 14px;
  }
  .signup-body .signup-column .signup-box .or-sec {
    margin: 10px 0px;
  }


}

@media screen and (max-width: 450px) {
  .signup-body .signup-column {
    width: 100%;
  }

  .signup-body .image-sec {
    display: none;
  }

  .signup-body .signup-column .signup-box {
    width: 90%;
  }

  .signup-body .signup-column .signup-box input {
    width: 100%;
  }

  .signup-body .signup-column .signup-box .pass-eye {
    width: 100%;
  }

  .signup-body .signup-column .signup-box .pass-eye input {
    width: 100%;
  }

  .signup-body .signup-column .signup-box .or-sec {
    width: 100%;
  }

  .signup-body .signup-column .signup-box .signup-google {
    margin-bottom: 15px;
    width: 100%;
  }

  .signup-body .signup-column .login-signup-link {
    width: 100%;
  }
}